export const formatDate = (date) => {
    let parsedDate;

    // Check if the input is a string
    if (typeof date === 'string') {
        parsedDate = new Date(date);
    } else if (date instanceof Date) {
        parsedDate = date;
    } else {
        return 'التاريخ غير محدد'; // Return a message indicating the date is not set
    }

    // Check if the parsed date is valid
    if (isNaN(parsedDate.getTime())) {
        return 'تاريخ غير صالح'; // Return a message indicating an invalid date
    }

    return parsedDate.toLocaleString('ar-EG', { 
        weekday: 'long', 
        day: '2-digit', 
        month: 'short', 
        year: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit' 
    });
}

export const productReview = (reviews) => {
    const totalReviews = reviews?.length || 0;
    const totalRatings = reviews?.reduce((acc, review) => acc + review.rate, 0) || 0;

    // Prevent division by zero
    const averageRating = totalReviews > 0 ? totalRatings / totalReviews : 0;

    // Match backend's floor rounding
    const flooredAverageRating = Math.floor(averageRating);

    return {
        totalReviews,
        totalRatings,
        averageRating: flooredAverageRating
    };
}




export function formatArabicDate(isoDate) {
    const date = new Date(isoDate);
  
    // Format the date part
    const dateFormatter = new Intl.DateTimeFormat('ar-EG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  
    // Format the time part
    const timeFormatter = new Intl.DateTimeFormat('ar-EG', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    });
  
    // Get the formatted parts
    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);
  
    // Combine the formatted date and time
    return `${formattedDate} الساعة ${formattedTime}`;
  }
  
  export function getPercentage(number1, number2) {
    if (number1 === 0) return 0; // To handle division by zero
    const percentage = (number2 / number1) * 100;
    return percentage > 100 ? 100 : percentage.toFixed(0);
  }
  

