import { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import "./modal.css";
import Backdrop from '../Backdrop';
import { dropIn } from '../../utils/modals';
import { Warning } from '@mui/icons-material';

function ReportModal({ handleClose, id }) {
  const [formData, setFormData] = useState({
    against: id,
    reason: 'احتيال',
    message: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const options = ["احتيال", "إساءة", "غير لائق", "مزيف", "أخرى"];

  const handleOptionClick = (option) => {
    setFormData((prev) => ({ ...prev, reason: option }));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setFormData((prev) => ({ ...prev, message: value }));
  };

  const handleShowSuccessNotification = () => {
    setSuccess(true);
    setTimeout(() => setSuccess(false), 10000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Basic validation
    if (!formData.reason || !formData.message.trim()) {
      setError('يرجى اختيار سبب وكتابة تفاصيل التقرير');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/mandoub/reports`,
        formData, {
          headers: {
            
              Authorization : `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`
          },
          withCredentials: true,
      }
      );
      setSuccess(true);
      handleShowSuccessNotification();
      handleClose();
      console.log('Report submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting report:', error);
      setError(
        error.response?.data?.message || 
        'حدث خطأ أثناء تقديم التقرير. الرجاء المحاولة مرة أخرى.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal !justify-start !w-2/3 !h-4/5"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="df-c w-full">
          <div className="df jc-sb">
            <div className="title">تقرير</div>
            <Warning />
          </div>
          <hr />
          <div className="mt-6">
            <div className="font-bold">أسباب الإبلاغ</div>
            <div className="options flex-wrap ta-c df">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`option !mt-6 ${
                    formData.reason === option ? 'active' : ''
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
          <div className="font-bold">هل يمكنك توضيح المشكلة؟ ساعدنا في الفهم</div>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="أدخل تفاصيل التقرير"
            className="w-full p-2 border rounded"
          ></textarea>
          {error && <div className="text-red-500 mt-2">{error}</div>}
          {success && <div className="text-green-500 mt-2">تم إرسال التقرير بنجاح</div>}
          <button
            className="simple-btn !mt-6 !w-1/2 mx-auto justify-center"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'جاري الإرسال...' : 'تأكيد'}
          </button>
        </div>
      </motion.div>
    </Backdrop>
  );
}

export default ReportModal;