import { AddCard, RemoveCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useFetchData } from "../hooks/useFetchData";

function OrderCoupon({ setCouponAmount }) {
  const [coupon, setCoupon] = useState(false);
  const [couponValue, setCouponValue] = useState("");
  const [couponSuccess, setCouponSuccess] = useState(false);

  const { loading, data, error } = useFetchData(
    `client-api/applyCoupon?couponCode=${encodeURIComponent(couponValue)}` ,
    [couponValue]
  );

  useEffect(() => {

    console.log(couponValue);
  
  }, [couponValue])
  

  

  useEffect(() => {
    if (data?.message) {
      setCouponSuccess(true);
      setCouponAmount(data?.coupon);
    }
  }, [data, setCouponAmount]);

  const handleCouponInput = (e) => {
    setCouponValue(e.target.value);
  };

  const handleRemoveCoupon = () => {
    setCoupon(false);
    setCouponValue("");
    setCouponSuccess(false);
    setCouponAmount("");
  };

  return (
    <div className={`df jc-sb bg-amber-200 p-4 ${data?.message ? "bg-success" : ""}`}>
      {!coupon && (
        <div
          className="cursor-pointer col-succ df"
          onClick={() => setCoupon(true)}
        >
          <AddCard />
          هل لديك كوبون خصم؟
        </div>
      )}
      {coupon && !couponSuccess && (
        <div className="df pr w-full">
          <input
            type="text"
            className={`w-full input ser-in ${error ? "border-red" : ""}`}
            value={couponValue}
            onChange={handleCouponInput}
            placeholder="أدخل الكوبون هنا"
          />
        </div>
      )}
      {(couponSuccess && coupon) && (
        <>
          <div
            className="df cursor-pointer col-err"
            onClick={handleRemoveCoupon}
          >
            <RemoveCircle />
            إزالة الكوبون
          </div>
          {data?.message && (
            <div>
              <div className="prodPr">خصم {data?.coupon.amount}%</div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OrderCoupon;
