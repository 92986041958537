import { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Search } from "@mui/icons-material";
import { Swiper, SwiperSlide } from 'swiper/react';
import "./team.css";
import TeamSlide from "../../components/team slide/TeamSlide";
import { Link } from "react-router-dom";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useFetchData } from "../../hooks/useFetchData";

function Team() {
  const [search, setSearch] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const { data } = useFetchData("client-api/team");
  
  const mandoubs = data?.mandoubs || [];
  const [filteredTeam, setFilteredTeam] = useState(mandoubs);
  const teamListRef = useRef(null); // Create a ref for the team-list div

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    const filtered = mandoubs.filter(
      (member) =>
        member.fullName.toLowerCase().includes(value) ||
        member.id.toLowerCase().includes(value)
    );
    setFilteredTeam(filtered);
  };

  // Effect to handle clicks outside the team-list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (teamListRef.current && !teamListRef.current.contains(event.target)) {
        setSearch(""); // Clear the search value
        setFilteredTeam(mandoubs); // Reset filtered team
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mandoubs]); // Dependency array includes mandoubs

  return (
    <div className="team pr df-c">
      <div className="">
        <div className="df-c !gap-20 ma py-40 p5 pr">
          <img 
            src="/img/beedash-.png" 
            className="flowerleft sdnone m-d-none !left-0 !right-auto dashed1 !w-96 !top-1/2 flowerleft-custom" 
            alt="" 
          />
          <div className="title title3 !text-5xl pr w-fit mx-auto">
            سُفَراؤنا <div className="wave"></div>
          </div>
          <div className="df w-1/3 sm-w-full mx-auto pr justify-center">
            <input
              type="text"
              className="input in-ser !w-full"
              placeholder="البحث حسب الاسم أو المعرف"
              value={search}
              onChange={handleSearch}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
            <Search className="ser" />
            {search && (
              <div className="team-list" ref={teamListRef} key={filteredTeam.map((m) => m.id).join()}>
                <AnimatePresence>
                  {filteredTeam.length > 0 ? (
                    filteredTeam.map((member, index) => (
                      <motion.div
                        key={member._id}
                        className="team-member"
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 50 }}
                        transition={{ duration: 0.2 * index }}
                      >
                        <img
                          src={`${member.img ? process.env.REACT_APP_UPLOADS_DIR + member.img : '/img/default.png'}`}
                          alt={member.fullName}
                          className="member-image"
                        />
                        <Link className="member-details" to={`/team/${member._id}`}>
                          <h3>{member.fullName}</h3>
                          <p>ID: {member.id}</p>
                        </Link>
                      </motion.div>
                    ))
                  ) : (
                    <motion.p
                      className="no-results"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      No results found
                    </motion.p>
                  )}
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="car pr">
        <img src="/img/spl2.png" className="sdnone spl2 z-t" alt="" />
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          style={{ zIndex: 5 }} // Ensure Swiper has a lower z-index than the image

        >
          {mandoubs.map((mandoub) => (
            <SwiperSlide key={mandoub._id}>
              <TeamSlide mandoub={mandoub} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Team;