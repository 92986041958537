import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Stars from '../../components/stars rate/Stars';
import { formatArabicDate } from '../../utils/functions';
import { useEffect, useState } from 'react';
import { Box, Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useAuthContext } from '../../hooks/useAuthContext';
import axios from 'axios';
import { Delete } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function getLabelText(value) {
  return `${value}`;
}

const ReviewsSection = ({ comments, id }) => {
  const [value, setValue] = useState(2);
  const [comment, setComment] = useState("");
  const [hover, setHover] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const { auth } = useAuthContext();
  const [commentsData, setcommentdata] = useState([]);
  const canReview = auth?.user?.allowedToReviewStaff?.includes(id);


  useEffect(() => {
    if (comments?.reviews) {
      setcommentdata(comments?.reviews)
    }
  }, [comments])
  

  const handleSubmitRating = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}mandoub/rate?id=${id}`,
        {
          rating: value,
          comment: comment
        },
        {
          headers: {
              Authorization : `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`
          },
          withCredentials: true,
      }
      );

      if(response.data) {
        setSuccessMessage(response.data.message || "تم إضافة التقييم بنجاح");
        setcommentdata([response.data.review,...commentsData])
        setComment("");
        toast.success("تم إضافة تقييمك بنجاح", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          rtl: true // Enable RTL for Arabic text
        });
      }
      
    } catch (err) {
      setError(err.response?.data?.error || "حدث خطأ ما");
      toast.error(err.response?.data?.error || "حدث خطأ أثناء إضافة التقييم", {
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        rtl: true
      });
      console.log(err);
      
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReview = async (reviewId) => {

    setIsDeleting(true);
    setError(null);
    
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}mandoub/rate?id=${id}&reviewId=${reviewId}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`,
          },
        }
      );
      
      setcommentdata(response.data.reviews)
      toast.success("تم حذف التقييم بنجاح", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        rtl: true
      });
    } catch (err) {
      setError(err.response?.data?.message || "حدث خطأ أثناء حذف التعليق");
      toast.error( "حدث خطأ أثناء حذف التعليق", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        rtl: true
      });
    } finally {
      setIsDeleting(false);
    }
  };

  if (!comments) return null;
  
  return (
    <div className="reviews">
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="df-c ai-c !w-10/12">
        <div className="df-c section-card comments-upper !w-100 !gap-6">
          <div className="title">التقييمات</div>
          <div className="df w-full sm-df-c !justify-between ai-stretch">
            <div className="df-c">
              <div className="bold-1">إجمالي التقييمات</div>
              <div>
                <div className="df">
                  <div className="bold-1 text-4xl">{comments?.stats.totalComments}</div> 
                </div>
                <div className="text">النمو في التقييمات  </div>
              </div>
            </div>
            <div className="df-c bordered">
              <div className="bold-1">متوسط التقييمات</div>
              <div>
                <div className="df">
                  <div className="bold-1 text-4xl">{comments?.stats.averageRating}</div> 
                  <Stars number={Math.floor(comments?.stats.averageRating)} />
                </div>
                <div className="text">النمو في التقييمات  </div>
              </div>
            </div>
            {comments && comments?.stats && <div className="df-c">
              <div className="bold-1">إجمالي التقييمات</div>
              <div className="rev-cont">
                <div className="df avg-star-nbr ai-fe">
                  <span className="nbr-indic">5</span> 
                  <div className="review-line-container">
                    <div className="line" style={{ width: `${comments?.stats.ratingPercentages[5]}%` }}></div>
                  </div>
                </div>
                <div className="df avg-star-nbr ai-fe">
                  <span className="nbr-indic">4</span> 
                  <div className="review-line-container" style={{ background: 'var(--cyan-light)' }}>
                    <div className="line" style={{ width: `${comments?.stats.ratingPercentages[4]}%`, background: 'var(--cyan)' }}></div>
                  </div>
                </div>
                <div className="df avg-star-nbr ai-fe">
                  <span className="nbr-indic">3</span> 
                  <div className="review-line-container" style={{ background: 'var(--purple-light)' }}>
                    <div className="line" style={{ width: `${comments?.stats.ratingPercentages[3]}%`, background: 'var(--purple)' }}></div>
                  </div>
                </div>
                <div className="df avg-star-nbr ai-fe">
                  <span className="nbr-indic">2</span> 
                  <div className="review-line-container" style={{ background: 'var(--shipped-light)' }}>
                    <div className="line" style={{ width: `${comments?.stats.ratingPercentages[2]}%`, background: 'var(--shipped)' }}></div>
                  </div>
                </div>
                <div className="df avg-star-nbr ai-fe">
                  <span className="nbr-indic">1</span> 
                  <div className="review-line-container">
                    <div className="line" style={{ width: `${comments?.stats.ratingPercentages[1]}%` }}></div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>

        {auth && canReview && <div className="review-form df-c !p-2" id="rate">
          <div className="df-c w-full align-center justify-center">
            <div className="title">كيف كان مستوى الخدمة؟</div>
            <p className='w-1/2 sm-w-full mx-auto'>
              يساعدنا رأيك في تحسين خدماتنا وفهم احتياجاتك بشكل أفضل لضمان تقديم خدمة تلبي توقعاتك.
            </p> 

            <Box className="sm-w-full" sx={{ margin: "2rem auto", display: 'flex', flexDirection: "column", direction: "ltr", alignItems: 'center' }}>
              <Rating
                name="hover-feedback"
                value={value}
                className=''
                precision={0.5}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              />
              {value !== null && (
                <Box sx={{ ml: 2 }}> 
                  {(hover !== -1 ? hover : value) === 4.5 && (
                    <DotLottieReact
                      src="/icons/happy.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 5 && (
                    <DotLottieReact
                      src="/icons/love.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem", transform:"scale(1.5)" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 0.5 && (
                    <DotLottieReact
                      src="/icons/mad.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 1 && (
                    <DotLottieReact
                      src="/icons/angry.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 1.5 && (
                    <DotLottieReact
                      src="/icons/cry.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 2 && (
                    <DotLottieReact
                      src="/icons/sad.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 2.5 && (
                    <DotLottieReact
                      src="/icons/______.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 3 && (
                    <DotLottieReact
                      src="/icons/___.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 3.5 && (
                    <DotLottieReact
                      src="/icons/____.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 4 && (
                    <DotLottieReact
                      src="/icons/_____.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                </Box>
              )}
            </Box>
            <textarea 
              className='w-50 sm-w-full m-auto max-h-24' 
              value={comment} 
              onChange={(e) => setComment(e.target.value)} 
              placeholder='تذكر أن تكون لطيفا' 
              name="" 
            />
            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
            {successMessage && <div className="text-green-500 text-sm mt-2">{successMessage}</div>}
            <button 
              className="simple-btn cursor-pointer justify-center sm-w-full df m-auto mt-5" 
              onClick={handleSubmitRating}
              disabled={loading}
            >
              {loading ? "جاري الإرسال..." : "إضافة تقييم"}
            </button>
          </div>
        </div>}

        {commentsData && <div className="comments !w-100 section-card">
          {commentsData.map((review, index) => (
            <div key={index} className="df comment ai-fs gap-8">
              <div className="df comment-user-img ai-stretch">
                <img
                  src={(review?.img ? review?.img : "/img/default.png")}
                  alt="user"
                />
                <div className="df-c jc-sb flex-1">
                  <div className="bold-1 text-lg">{review.name} {review.surname}</div>
                  <div>
                    <div className="text">{review.orders}</div>
                  </div>
                </div>
              </div>
              <div className="comment-content df-c flex-1">
                <div className="text df text-xs justify-between w-full">
                  <div className="df gap-2">
                    <Stars number={review.rating} /> 
                    {formatArabicDate(review.createdAt)}
                  </div>
                  {auth && review.clientId && review.clientId === auth.user._id && (
                    <button 
                      onClick={() => handleDeleteReview(review._id)}
                      disabled={isDeleting}
                      className="text-red-500 hover:text-red-700 transition-colors"
                      title="حذف التعليق"
                    >
                      <Delete size={18} />
                    </button>
                  )}
                </div>
                <div className="text">{review.comment}</div>
              </div>
            </div>
          ))}
        </div>}
      </div>
      {error && (
        <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded">
          {error}
        </div>
      )}
    </div>
  );
};

export default ReviewsSection;