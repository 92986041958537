import { useState, useEffect } from "react";
import Stars from "../../components/stars rate/Stars";
import { formatArabicDate } from '../../utils/functions';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import { useAuthContext } from '../../hooks/useAuthContext';

function Reviews() {
  const [filter, setFilter] = useState("all");
  const [reviews, setReviews] = useState({ all: [], product: [], mandoub: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { auth } = useAuthContext();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}client-api/client-rev`,
          { withCredentials: true, headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`,
          },}
        );
        if (response.data.success) {
          setReviews(response.data.reviews);
        }
      } catch (err) {
        setError(err.response?.data?.message || "حدث خطأ في تحميل التقييمات");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  const handleDeleteReview = async (reviewId, type, targetId) => {

    setIsDeleting(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}client-api/client-rev?reviewId=${reviewId}&type=${type}&targetId=${targetId}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`,
          },
        }
      );

      if (response.data.success) {
        setReviews(prev => ({
          all: prev.all.filter(review => review._id !== reviewId),
          product: prev.product.filter(review => review._id !== reviewId),
          mandoub: prev.mandoub.filter(review => review._id !== reviewId)
        }));
      }
    } catch (err) {
      setError(err.response?.data?.message || "حدث خطأ أثناء حذف التقييم");
    } finally {
      setIsDeleting(false);
    }
  };

  const getReviewsToDisplay = () => {
    return reviews[filter] || [];
  };

  const getImageUrl = (review) => {
    if (review.type === 'product' && review.targetImage?.url) {
      return review.targetImage.url;
    } else if (review.type === 'mandoub' && review.targetImage) {
      return `${process.env.REACT_APP_UPLOADS_DIR}${review.targetImage}`;
    }
    return "/img/default.png";
  };

  const getDisplayName = (review) => {
    if (review.type === 'product') {
      return review.targetName;
    }
    return `${review.targetName || `${review.name} ${review.surname}`}`;
  };

  if (loading) {
    return <div className="text-center p-4">جاري التحميل...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">{error}</div>;
  }

  return (
    <div className="comments !w-full df-c section-card">
      <div className="df header-btns jc-sb filter-bar gap-4 bg-white px-4 py-6">
        <div className="df sm-df-c sm-ai-fs text-sm">
          <label className="df filter-option">
            <input
              type="radio"
              name="filter"
              value="all"
              checked={filter === "all"}
              onChange={(e) => setFilter(e.target.value)}
            />
            <span>عرض الكل</span>
          </label>
          <label className="df filter-option">
            <input
              type="radio"
              name="filter"
              value="product"
              checked={filter === "product"}
              onChange={(e) => setFilter(e.target.value)}
            />
            <span>المنتجات</span>
          </label>
          <label className="df filter-option">
            <input
              type="radio"
              name="filter"
              value="mandoub"
              checked={filter === "mandoub"}
              onChange={(e) => setFilter(e.target.value)}
            />
            <span>المندوبون</span>
          </label>
        </div>
        <div className="text-xs filtres">
          نتائج ({getReviewsToDisplay().length})
        </div>
      </div>

      {getReviewsToDisplay().map((review) => (
        <div
          key={review._id}
          className="df sm-df-c bg-white comment ai-fs gap-8 !px-4"
        >
          <div className="df comment-user-img ai-stretch">
            <img
              src={getImageUrl(review)}
              className={`w-16 h-16 rounded-lg ${review.type === 'product' ? 'object-contain' : 'object-cover'}`}
              alt={review.type === 'product' ? review.targetName : review.targetName}
              onError={(e) => {
                e.target.src = "/img/default.png";
                e.target.onerror = null;
              }}
            />
            <div className="df-c jc-sb flex-1">
              <div className="bold-1 text-lg">
                {getDisplayName(review)}
              </div>
              <div>
                <div className="text">
                  {review.type === 'product' ? 'منتج' : 'مندوب'}
                </div>
                <div className="text">{review.orders} طلبات</div>
              </div>
            </div>
          </div>
          <div className="comment-content df-c flex-1">
            <div className="text df text-xs justify-between w-full">
              <div className="df gap-2">
                <Stars number={review.rating} />
                {formatArabicDate(review.createdAt)}
              </div>
              {auth && auth.user._id === review.clientId && (
                <div className="df gap-2">
                  <button
                    onClick={() => handleDeleteReview(review._id, review.type, review.targetId)}
                    disabled={isDeleting}
                    className="text-red-500 hover:text-red-700 transition-colors"
                    title="حذف التقييم"
                  >
                    <Delete />
                  </button>
                </div>
              )}
            </div>
            <div className="text">{review.comment}</div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Reviews;