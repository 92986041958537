import Navbar from './components/navbar/Navbar';
import Checkout from './pages/checkout page/Checkout';
import Home from './pages/home page/Home';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import OurTeam from './pages/our team page/OurTeam';
import Footer from './components/footer/Footer';
import ProductDetails from './pages/product details/ProductDetails';
import VerifyPage from './pages/verify/verifyPage';
import Profile from './pages/profile page/Profile';
import Settings from './pages/profile page/Settings';
import Password from './pages/profile page/Password';
import Orders from './pages/profile page/Orders';
import Whishlists from './pages/profile page/Whishlists';
import Reviews from './pages/profile page/Reviews';
import Faq from './pages/faq/Faq';
import Team from './pages/team page/Team';
import "./reponsive300.css";
import "./responsive1100.css";
import Coupans from './pages/profile page/Coupans';
import Cart from './components/cart/Cart';
import HoneyProductsPages from './pages/honey products page/HoneyProductsPages';
import HoneyProductDetailsPage from './pages/honey products page/HoneyProductDetailsPage';
import ScrollToTop from './components/ScrollToTop';
import Terms from './pages/terms/Terms';
import { useFetchData } from './hooks/useFetchData';
import { useEffect } from 'react';
import { useAuthContext } from './hooks/useAuthContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Apply from './pages/apply page/Apply';
import { useSettingsContext } from './hooks/useSettingsContext';
import ThankYou from './pages/apply page/ThankYou';
import LoadingPage from './components/loading page/LoadingPage';
import ErrorPage from './components/loading page/ErrorPage';
import ResetPassword from './pages/verify/ResetPassword';
import Authen from './pages/Authen';

// This wrapper component will handle the auth check with location dependency
function AppContent() {
  const { dispatch } = useAuthContext();
  const { dispatch: dispatchSettings } = useSettingsContext();
  const location = useLocation();
  
  // Use the pathname as a dependency for the auth check
  const { data: authData } = useFetchData("client-api/checkauth", [location.pathname]);
  const { data: settingsData, loading, error } = useFetchData("settings");

  useEffect(() => {
    if (settingsData) {
      dispatchSettings({ type: "SET-SETTINGS", payload: settingsData.settings });
      document.documentElement.style.setProperty('--primary', settingsData.settings.color.primary);
    }
  }, [settingsData, dispatchSettings]);
  
  useEffect(() => {
    if (authData && authData.user && authData.user.name) {
      dispatch({ type: "LOGIN", payload: authData });
    }
  }, [authData, dispatch]);

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  const metaInf = settingsData?.settings.seo;

  return (
    <>
      <Helmet>
        <title>{metaInf?.title}</title>
        <meta name="description" content={metaInf?.description} />
        <meta name="keywords" content={metaInf?.keyword} />
        {settingsData?.settings.logo && settingsData.settings.logo[0]?.url && (
          <link rel="icon" href={settingsData.settings.logo[0].url} />
        )}
      </Helmet>
      <Navbar />
      <Cart />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/thanks/:id" element={<ThankYou />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/authentificate/:token" element={<Authen />} />
        <Route path="/verify-email/:token" element={<VerifyPage />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/profile" element={<Profile />}>
          <Route index element={<Settings />} />
          <Route path="page1" element={<Settings />} />
          <Route path="page2" element={<Password />} />
          <Route path="page3" element={<Orders />} />
          <Route path="page4" element={<Whishlists />} />
          <Route path="page5" element={<Reviews />} />
          <Route path="page6" element={<Coupans />} />
        </Route>
        <Route path="/team/:id" element={<OurTeam />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/team" element={<Team />} />
        <Route path="/honey-products" element={<HoneyProductsPages />} />
        <Route path="/honey-products/:id" element={<HoneyProductDetailsPage />} />
        <Route path="/details/:id" element={<ProductDetails />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          <AppContent />
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;