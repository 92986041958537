import { Link } from "react-router-dom";
import Stars from "../../components/stars rate/Stars";
import { useCartContext } from "../../hooks/useCartContext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

function Product({ list, product }) {
  const { dispatch } = useCartContext();
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");

  useEffect(() => {
    if (product?.colors?.length > 0) {
      setSelectedColor(product.colors[0].value);
    }
    if (product?.sizes?.length > 0) {
      setSelectedSize(product.sizes[0]);
    }
  }, [product]);

  const handleColorSelect = (color) => {
    setSelectedColor(color.value);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  const handleAddToCart = () => {
    const selectedProduct = {
      ...product,
      selectedColor,
      selectedSize,
    };
    
    console.log("Selected Product", selectedProduct);
    dispatch({ type: "ADD_PRODUCT", payload: selectedProduct });
  };

  return (
    <div
      className={`container-prod sm-w-full sm-df-c ai-stretch ${
        list ? "df  !w-full mx-auto" : "df-c w-col"
      }`}
    >
      <div>
        <div className="images">
          <img
            src={product?.image?.[0]?.url || "default-image-url.jpg"}
            alt={product?.name || "Product Image"}
          />
        </div>

       {/*  <p className="pick">اختر اللون والمقاس</p>
        <div className="sizes df w-full">
          {product?.colors?.map((color, index) => (
            <div
              key={index}
              style={{ background: color.value }}
              className={`color ${
                color.value === selectedColor ? "selected-col" : ""
              }`}
              onClick={() => handleColorSelect(color)}
            ></div>
          ))}
        </div>
        <div className="sizes">
          {product?.sizes?.map((size, index) => (
            <div
              key={index}
              className={`size ${size === selectedSize ? "selected-s" : ""}`}
              onClick={() => handleSizeSelect(size)}
            >
              {size}
            </div>
          ))}
        </div> */}
      </div>
      <div className="product flex1">
        <div className="df">
          <h2>{product?.price} ر.س</h2> - {/* <Stars number={0} /> */}
        </div>
        <p>{product?.subcategories?.[0]}</p>
        <Link to={`./${product._id}`} className="title pr w-fit">
          <div className="wave"></div>
          {product?.name || "Product Title"}
        </Link>
        <div className="buttons mt-5 df">
          <Link to={`./${product._id}`} className="simple-btn !px-2 flex1 justify-center">
            تحديد الخيارات
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Product;
