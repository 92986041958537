import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import usePostData from '../../hooks/usePostData';

function AdSlide({ primaryColor, element }) {

  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.1 }); 

  // Variants for shape1
  const shape1Variants = {
    hidden: { transform: "translate(0%, 0%) rotate(0deg) scale(0.2)" }, // Initial state
    visible: {
      transform: "translate(-50%, 50%) rotate(45deg) scale(1)", // Target state
      transition: { duration: 0.8 },
    },
  };

  const { postData, loading, data, error } = usePostData(); 


  const handleAdClick = async (e) => {
    e.preventDefault();
    const url = element.url;
  
    try {
      await postData("page/home?scope=incrementClicks", { adId: element.id });
    } finally {
      // Navigate even if tracking fails
      window.location.href = url;
    }
  };

  // Variants for shape2
  const shape2Variants = {
    hidden: { transform: "translate(-100%, 0%) rotate(0deg) scale(0.2)" }, // Initial state
    visible: {
      transform: "translate(50%, -50%) rotate(45deg) scale(1)", // Target state
      transition: { duration: 0.8, delay: 0.8 },
    },
  };

  return (
    <div
      className="df ad-slide sm-df-c sm-items-center"
      style={{ borderColor: primaryColor }}
      ref={ref}
    >
      <div
        className="img-ad-cont"
        style={{ backgroundColor: primaryColor }}
      >
     
        <motion.div
          className="shape1"
          style={{ backgroundColor: primaryColor }}
          variants={shape1Variants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        ></motion.div>

        {/* Shape 2 */}
        <motion.div
          className="shape2"
          style={{ backgroundColor: primaryColor }}
          variants={shape2Variants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        ></motion.div>

        <img src={element?.image[0].url? element?.image[0].url : "/img/about.png"} alt={element?.title} />
      </div>
      <div className="contt pr df-c sm-items-center !gap-8">
        <div className="df-c">
        <div className="title pr !w-fit">
          {element?.title}
          <div
            className="wave"
            style={{ textDecorationColor: primaryColor }}
          ></div>
        </div>

        <div className="subheader">{element.subTitle}</div>
        </div>
        <p className="!text-lg">
          {element.description}
        </p>
        <a href={element.url}
          onClick={handleAdClick}
          target="_blank"
          rel="noopener noreferrer"
          className="simple-btn w-2/3 !justify-center !border-none"
          style={{ backgroundColor: primaryColor }}
        >
          اكتشف المزيد
        </a>
      </div>
    </div>
  );
}

export default AdSlide;
