import axios from 'axios';
import { useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';

function LoginForm({ toggleForm, toggleForgotPassword,handleClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setloading] = useState(false);
  const {dispatch} = useAuthContext();
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setloading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}client-api/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      
      if (response.data?.token) {
        localStorage.setItem('auth', JSON.stringify(response.data));

          console.log(response.data);
          
        dispatch({ type: "LOGIN", payload: response.data });
        setloading(false);
      } else {
        console.log('Login failed:', response.data);
        setError(response.data);
        setloading(false);
      }
    } catch (error) {
      console.error('Login error:', error.response?.data );
      setError(error.response?.data.message || error.message);
      setloading(false);
    }
  };

  const translateError = (error) => {
    switch (error) {
      case 'Invalid password':
        return 'الرقم السري غير صحيح';
      case 'User not found':
        return 'المستخدم غير موجود';
      default:
        return error;
    }
  };

  return (
    <div className="form login">
      
      <span className="title">تسجيل الدخول</span>
      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <input
            type="text"
            placeholder="أدخل بريدك الإلكتروني"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <i className="uil uil-envelope icon"></i>
        </div>
        <div className="input-field">
          <input
            type="password"
            className="password"
            placeholder="أدخل كلمة المرور"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <i className="uil uil-lock icon"></i>
          <i className="uil uil-eye-slash showHidePw"></i>
        </div>
        {error && !loading && <div className="error-message mt-4">فشل : {translateError(error)}</div>}
        {loading && <div className="error-message">الرجاء الإنتظار...</div>}
        <div className="input-field button">
          <input disabled={loading === true} type="submit" value="تسجيل الدخول" />
        </div>
        <a href="https://admin.alrheeq.com/api/auth/alrheeq/google" rel="noopener noreferrer" type="button" className="inline-block login-with-google-btn mt-8">
          تسجيل الدخول باستخدام جوجل
        </a>
      </form>
      <div className="login-signup">
        <span className="text">
          لست عضواً؟{' '}
          <a href="#" className="text signup-link" onClick={toggleForm}>
            اشترك الآن
          </a>
        </span>
      </div>
      <div className="flex justify-center items-center mb-4">
          <a 
            href="#" 
            className="text signup-link text-center"
            onClick={(e) => {
              e.preventDefault();
              toggleForgotPassword();
            }}
          >
            نسيت كلمة المرور؟
          </a>
        </div>
    </div>
  );
}

export default LoginForm;
