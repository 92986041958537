function Empty({message}) {
  return (
    <div className="df w-fit">
        <div className="w-16 h-16 animate-up-down">
            <img src="/img/warning.png" className='w-full h-full object-cover' alt="" />

        </div>
        {!message &&<div>
          
        لا يوجد شيء هنا 
        </div>}
        {message &&<div>
          
         {message}
        </div>}
    </div>
  )
}

export default Empty