import ProfileHeader from "../../components/profileheader/ProfileHeader";
import { useParams } from 'react-router-dom';
import ReviewsSection from "./ReviewsSection";
import { useFetchData } from "../../hooks/useFetchData";
import { useAuthContext } from "../../hooks/useAuthContext";

function OurTeam() {
  const {auth} = useAuthContext()
  const { id } = useParams();
  const { data: comments,loading } = useFetchData("mandoub/reviews?id=" + id,[auth]);



  return (
    <div className="container-padding">
      <ProfileHeader id={id} />

      {comments &&<ReviewsSection comments={comments} id={id} />}
      {loading &&"الرجاء الانتظار"}
    </div>
  );
}

export default OurTeam;