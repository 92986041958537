import { TiTick } from "react-icons/ti";
import { useParams } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { useSettingsContext } from "../../hooks/useSettingsContext";

const ThankYou = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetchData("client-api/order?id=" + id);
  const {settings} = useSettingsContext();
  
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500 text-xl">حدث خطأ في تحميل البيانات</div>
      </div>
    );
  }

  const order = data?.order;

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg">
        {/* Thank You Header */}
        <div className="text-center py-8 border-b border-gray-200">
          <div className="rounded-full bg-green-100 p-3 w-16 h-16 mx-auto mb-4">
            <TiTick className="w-10 h-10 text-green-500" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-2">شكراً لطلبك!</h1>
          <p className="text-gray-600">
            تم استلام طلبك بنجاح وسيتم معالجته في أقرب وقت ممكن
          </p>
        </div>

        {/* Order Details */}
        <div className="px-6 py-6">
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">تفاصيل الطلب</h2>
            <div className="bg-gray-50 rounded-md p-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-600">رقم الطلب:</p>
                  <p className="font-medium">{order?.orderId}</p>
                </div>
                <div>
                  <p className="text-gray-600">تاريخ الطلب:</p>
                  <p className="font-medium">
                    {new Date(order?.orderDate).toLocaleDateString('ar-SA')}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600">حالة الطلب:</p>
                  <p className={ `font-medium status ${order?.status}`}>{order?.status == "Pending" ? "قيد المراجعة" : order?.status }</p>
                </div>
                <div>
                  <p className="text-gray-600">طريقة الدفع:</p>
                  <p className="font-medium">{order?.paymentMethod}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Products */}
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">المنتجات</h2>
            {order?.products?.[0]?.map((product, index) => (
              <div key={index} className="flex items-center py-4 border-b border-gray-200 last:border-0">
                <div className="flex-1">
                  <h3 className="font-medium">{product.name}</h3>
                  <p className="text-gray-600">
                    الكمية: {product.qteToBuy}
                  </p>
                </div>
                <div className="text-lg font-semibold">
                  {product.price} {settings?.currency.name}
                </div>
              </div>
            ))}
          </div>

          {/* Totals */}
          <div className="border-t border-gray-200 pt-4">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-600">تكلفة التوصيل:</span>
              <span>{order?.deliveryInfo.cost} ر.س</span>
            </div>
            <div className="flex justify-between items-center text-lg font-semibold">
              <span>المجموع:</span>
              <span>{order?.totalPrice} ر.س</span>
            </div>
          </div>
        </div>

        {/* Shipping Info */}
        <div className="bg-gray-50 px-6 py-6 rounded-b-lg">
          <h2 className="text-xl font-semibold mb-4">معلومات التوصيل</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-600">الاسم:</p>
              <p className="font-medium">{order?.shippingInfo.name}</p>
            </div>
            <div>
              <p className="text-gray-600">العنوان:</p>
              <p className="font-medium">
                {order?.shippingInfo.house}, {order?.shippingInfo.city}
              </p>
            </div>
            <div>
              <p className="text-gray-600">البلد:</p>
              <p className="font-medium">{order?.shippingInfo.country}</p>
            </div>
            <div>
              <p className="text-gray-600">الرمز البريدي:</p>
              <p className="font-medium">{order?.shippingInfo.zipCode}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;