import { BsEye } from "react-icons/bs";
import { IoMdStar } from "react-icons/io";
import Empty from "../empty/Empty";
import { useEffect, useState } from "react";
import { useSettingsContext } from "../../hooks/useSettingsContext";
import { Link } from "react-router-dom";



function OrdersContent({ selectedOrder }) {
    const [products, setProducts] = useState([]);
    
  useEffect(() => {
    if (selectedOrder.products) {
      setProducts(selectedOrder.products[0]);
    }
  }, [selectedOrder]);
  const {settings} = useSettingsContext();

  const formatArabicDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('ar-EG', options) + ' ' + date.toLocaleTimeString('ar-EG', { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <>
      {!selectedOrder && <div className="orders-content bg-white df-c flex-1 p-4">
        <Empty message={"اختر طلبًا"} />
      </div>}
      {selectedOrder && <div className="orders-content bg-white df-c flex-1 p-4">
        <div className="df jc-sb">
          <div>تفاصيل الطلب - {selectedOrder.orderId}</div>
          <BsEye />
        </div>
        <div className="order-indic df sm-df-c sm-gap-2 jc-sb mt-4 py-6 px-8">
          {selectedOrder.status === "Pending" && <div className="df  sm-w-full sm-justify-sb">
            <div>حالة الطلب :</div>
            <div className="status pending">جارٍ معالجة الطلب</div>
          </div>}
          {selectedOrder.status === "In Progress" && <div className="df  sm-w-full sm-justify-sb">
            <div>حالة الطلب :</div>
            <div className="status pending">جارٍ معالجة الطلب</div>
          </div>}
          {selectedOrder.status === "Canceled" && <div className="df  sm-w-full sm-justify-sb">
            <div>حالة الطلب :</div>
            <div className="status failed">ملغى</div>
          </div>}
          {selectedOrder.status === "Delivered" && <div className="df  sm-w-full sm-justify-sb">
            <div>حالة الطلب :</div>
            <div className="status paid">تم التسليم</div>
          </div>}
          {selectedOrder.paymentStatus === "Paid" && <div className="df sm-w-full sm-justify-sb">
            <div>حالة الدفع :</div>
            <div className="status paid">مدفوع</div>
          </div>}
          {selectedOrder.paymentStatus !== "Paid" && <div className="df sm-w-full sm-justify-sb">
            <div>حالة الدفع :</div>
            <div className="status pending">قيد الانتظار</div>
          </div>}
        </div>
        <div className="df sm-df-c sm-gap-2 jc-sb ai-fs">
          <div className="w-3/5 sm-w-full df-c">
            <div className="font-bold">عنوان الفاتورة</div>
            <div>{selectedOrder.billingInfo.country}, {selectedOrder.billingInfo.state}, {selectedOrder.billingInfo.zipcode}, {selectedOrder.billingInfo.house}</div>
            <div className="font-bold">العنوان</div>
            <div>{selectedOrder.shippingInfo.country}, {selectedOrder.shippingInfo.state}, {selectedOrder.shippingInfo.zipcode}, {selectedOrder.shippingInfo.house}</div>
          </div>
          <div className="flex-1 sm-w-full df-c">
            <div className="df jc-sb">
              <div className="font-bold">الإجمالي الجزئي</div>
              <div>{settings?.currency.name} {selectedOrder.totalPrice - selectedOrder.deliveryInfo.cost}</div>
            </div>
            <div className="df jc-sb">
              <div className="font-bold">رسوم الشحن</div>
              <div>{settings?.currency.name} {selectedOrder.deliveryInfo.cost}</div>
            </div>
            {selectedOrder.coupon.discount && <div className="df jc-sb">
              <div className="font-bold">خصم الكوبون</div>
              <div>%{selectedOrder.coupon.discount}</div>
            </div>}
            <div className="df jc-sb font-bold">
              <div className="font-bold">المبلغ المستحق</div>
              <div>{settings?.currency.name} {selectedOrder.payAmount}</div>
            </div>
          </div>
        </div>
        <div className="order-track">
          <div className="df ai-fs">
            <div className="col-12 col-md-10 hh-grayBox pt45 pb20 w-full scrolable">
              <div className={`trackcont ${selectedOrder.status}`}>
                <div className="order-tracking completed">
                  <span className="is-complete"></span>
                  <p>تم الطلب<br /><span>{formatArabicDate(selectedOrder.orderDate)}</span></p>
                </div>
                <div className={`order-tracking ${selectedOrder.status === 'In Progress' || selectedOrder.status === "Delivered" ? 'completed' : ''}`}>
                  <span className="is-complete"></span>
                  <p>الشحن إلى العميل<br /><span>{formatArabicDate('2023-06-24T10:50:00')}</span></p>
                </div>
                <div className={`order-tracking ${selectedOrder.status === "Delivered" ? 'completed' : ''}`}>
                  <span className="is-complete"></span>
                  <p>تم التسليم<br /><span>{formatArabicDate('2023-06-28T10:50:00')}</span></p>
                </div>
              </div>
            </div>
          </div>
          {selectedOrder.staff &&
          <Link to={`/team/${selectedOrder.staff._id}`} className="df">
          <div className="df">       
              <div className="order-cus-img">
                <img src={selectedOrder.staff.img ? selectedOrder.staff.img : "/img/default.png"} />
              </div>{" "}
              <div className="df-c g0">
                <div className="font-bold !text-black">  السفير : {selectedOrder.staff.fullName} </div>
                <div className="text small-text">البريد الإلكتروني: {selectedOrder.staff.email}</div>
              </div>
            </div>
            </Link>}
          <div className="df mt-12 ta-c men font-bold">
            <div className="product flex1">المنتج</div>
            <div className="qte w-1/5">الكمية</div>
            <div className="price w-1/5">السعر</div>
          </div>
          <div className="cardcontent  !h-auto">
            {products.map(product => (
              <div key={product.id} className="product-cart bg-white p-4" initial="hidden" animate="visible" exit="exit" transition={{ duration: 0.4, ease: 'easeInOut' }}>
                <div className="product-img">
                  {product.isGift && <img src="/img/gift.png" alt="" className="gifted" />}
                  <img src="/img/polygon.png" alt="" className="poly" />
                  <img 
                    src={
                      `${product.isGift ? product.img : (Array.isArray(product?.image) && product?.image[0]?.url ? product?.image[0]?.url : '/img/default.png')}`
                    }
                    alt={product.name}
                    className="product-cart-img"
                  />
                </div>
                <div className="details-cart-prod flex1">
                  <div className="font-bold">{product.name}</div>
                  {product.unit === "كغ" && (
                    <>
                      <div className="df sm-w-fit sm-mx-auto">
                        <div className="desc-cart !h-auto">الكمية الإجمالية :</div>
                        <div className="prodPr">كغ {(+product.unitValue).toFixed(2) * product.qteToBuy}</div>
                      </div>
                    </>
                  )}
                  {product.isGift && (<div className="status paid">مستوى الهدية: {product.giftLevel}</div>)}
                  <div className="df mt-2">
                    <div className="prodPr">ر.س{(product?.price?.toFixed(2)) ? (product?.price?.toFixed(2)) : 0}</div> -
                    <div className="rate df !gap-1">{product.rate}
                      <IoMdStar className="!text-white" />
                    </div> 
                  </div>
                  {
                    product.type !== "simple" && !product.isGift && (
                      <div className="df mt-2">
                        <div className="prodPr"><div
                          style={{ background: product.selectedColor }}
                          className={`color`}
                        ></div></div> -
                        <div className="sizes df !gap-1">
                          <div className="size">{product.selectedSize}</div>
                        </div> 
                      </div>
                    )
                  }
                </div>
                <div className="prodPr">
                  {product.qteToBuy}
                </div>
                <div className="but w-1/5 ta-c">
                  <div className="prodPr">{!product.isGift ? product.discount * product.qteToBuy : "مجاني"}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
    </>
  );
}

export default OrdersContent;