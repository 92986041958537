import { useState } from "react";

const useModal = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});


  const close = () => {
    setModalOpen(false);
    setModalProps({});
  };

  const open = (props = {}) => {
    setModalProps(props);
    setModalOpen(true);
  };

  return { modalOpen, close, open,modalProps };
};

export default useModal;
