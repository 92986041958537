import { IoIosCart } from "react-icons/io";
import { motion } from "framer-motion";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useCartContext } from "../../hooks/useCartContext";
import Stars from "../stars rate/Stars";
import { productReview } from "../../utils/functions";
import { useSettingsContext } from "../../hooks/useSettingsContext";

function Product({ list, prod }) {
  const {dispatch} = useCartContext();
  const [isAnimating, setIsAnimating] = useState(false);
  const flyOutImageRef = useRef(null);

  

  const flyImageVariants = {
    hidden: { opacity: 0.5, scale: 1 },
    visible: {
      opacity: 0.7,
      scale: 0.1,
      x: "100vw",
      transition: { duration: 1, ease: "easeInOut" },
    },
  };

  const {settings} = useSettingsContext();

 

  const handleAddToCart = () => {
    dispatch({type : "ADD_PRODUCT", payload : prod})
    console.log("seeeeeelected prod",prod);
    
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false); 
    }, 1000); 
  };

  const {averageRating} = productReview(prod.review);


  return (
    <>
      {!list && (
        <motion.div
          className="df-c !gap-0 ta-c ai-c product"
          style={{ direction: "rtl" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="pos"></div>
          <div className="product-img">
           {prod?.gallery[0]?.url && <img src={prod?.gallery[0]?.url} alt="" className="prod-logo" srcSet="" />}
            <img src="/img/shadow.png" alt="" className="shad" srcSet="" />
            <img src={prod.image[0].url} alt="" className="prod" srcSet="" />


            {isAnimating && (
              <motion.img
                ref={flyOutImageRef}
                src={prod.image[0].url}
                alt=""
                className="fly-out-image"
                initial="hidden"
                animate="visible"
                variants={flyImageVariants}
                onAnimationComplete={() => {
                  setIsAnimating(false);
                }}
                />
              )}
          </div>
          <Link to={`/details/${prod._id}`} className="title mt-12">
            {prod.name}
          </Link>
          <div className="df">
          <div className={`title v-p ${prod.discount !== prod.price ? '!text-xs !text-red-700 !line-through' : ''} `}>
            {prod.price} {settings?.currency.name}
            
          </div> 
          {prod.discount && prod.discount !== prod.price &&<div className="title v-p">
            {prod.discount} {settings?.currency.name}
            
          </div> }

          </div>
          <div className="df w-fit">
               {!prod?.new &&
            <Stars number={averageRating} /> } - <div className="prodPr">{prod.unitValue} {prod.unit}</div>
          </div>
            
          <div className="df w-fit">
            <div className="prodPr">{prod.quantity === -1 ? null : prod.quantity === 0 ? (
              <div className="prodPr pt-12" style={{ color: 'red' }}>غير متوفر</div>
            ) : (
              <div className="prodPr">المخزون : {prod.quantity}</div>
            )}</div>
          </div>
          {prod.quantity !== 0 && (
          <div className="btn mt-8" onClick={handleAddToCart}>
            أضف إلى السلة
            <div className="ic">
              <IoIosCart />
            </div>
          </div>
          )}
          
        </motion.div>
      )}
    </>
  );
}

export default Product;