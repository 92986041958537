import { NavLink } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';

function ProfileSidebar() {
  const {auth} = useAuthContext()
  return (
    <div className="bg-white p-8 profile-sidebar">
        <ul className='df-c'>
          <li><NavLink index to="page1">المعلومات</NavLink></li>
          {!auth?.user.googleId &&<li><NavLink to="page2">تغيير كلمة المرور</NavLink></li>}
          <li><NavLink to="page3">الطلبات</NavLink></li>
          <li><NavLink to="page5">التقييمات والمراجعات</NavLink></li>
          <li><NavLink to="page6">كوبونات</NavLink></li>
        </ul>
      </div>
  )
}

export default ProfileSidebar;
