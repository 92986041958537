import { useState, useEffect, useRef } from 'react';
import "./navbar.css";
import { GiHoneyJar } from "react-icons/gi";
import { IoMdArrowDropdown } from "react-icons/io";
import { useHoneyContext } from '../../hooks/useHoneyContext';
import { Chat, HiveRounded, HomeOutlined, Login, Logout, LogoutOutlined } from '@mui/icons-material';
import { FaUserGroup } from 'react-icons/fa6';
import { Link, NavLink } from 'react-router-dom';
import { useFetchData } from '../../hooks/useFetchData';
import { useAuthContext } from '../../hooks/useAuthContext';
import { CiLogin } from 'react-icons/ci';
import useModal from '../../hooks/useModal';
import ModalContainer from '../Modal/ModalContainer';
import Authentification from '../Modal/Authentification';

function Navbar() {
  const { dispatch,selectedHoney } = useHoneyContext();
  const {auth, dispatch: authDispatch} = useAuthContext();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("اختر العسل الذي تريده"); // Default to the first item
  const submenuRef = useRef(null);
  const menuRef = useRef(null);
  const {data} = useFetchData("client-api/categories")
  const {open,close, modalOpen} = useModal();

  const toggleMenu = () => {
    setIsOpenMenu((prev) => !prev); // Toggle the menu open/close
  };


  const handleLogOut = async () => {
    try {
      // Dispatch the LOGOUT action to update the app state
      authDispatch({ type: 'LOGOUT' });
  
      // Remove the auth data from localStorage
      localStorage.removeItem('auth');
  
      console.log('Logged out successfully');
    } catch (err) {
      console.error('Error during logout:', err);
    }
  };
  

  const handleClickOutside = (event) => {
    // Close the menu if clicking outside both the submenu and menu trigger
    if (
      submenuRef.current &&
      menuRef.current &&
      !submenuRef.current.contains(event.target) &&
      !menuRef.current.contains(event.target)
    ) {
      setIsOpenMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle item selection and close the submenu
  const handleItemClick = (itemText) => {
    setSelectedItem(itemText);
    setIsOpenMenu(false);
    dispatch({ type: "SET-HONEY", payload: itemText });
  };

  return (
    <>
    <nav className={`py-1 px-4 m-w-full sm-gapping pr sm-ai-fs ${isOpen ? "nav-open" : ""}`}>
    <ModalContainer>
        {modalOpen&& !auth && (
            <Authentification
                modalOpen={modalOpen}
                handleClose={close}
                top={true}
                
            />
        )}
    </ModalContainer>
      {auth && <NavLink to="/profile" className="!w-fit sm-min df comment-user-img">
            <img
              src={`${auth?.user?.image? auth?.user?.image : "/img/default.png"}`}
              className={`!w-12 !h-12`}
              alt="user"
            />
            <div className="m-d-none">{auth.user.name}</div>
          </NavLink>}
      {!auth && <div onClick={open} className='df cursor-pointer sdnone mr-8 mt-2'><CiLogin />الدخول</div>}
      <div id="menu" className='sm-mx-auto w-fit sm-w-full' ref={menuRef}>
        <div onClick={toggleMenu} className={`menu-item ${isOpenMenu ? "select" : ""}`}>

            <div className="menu-text">
              <div className="df jc-sb">

              <div className="df">
                <GiHoneyJar /> <div>{selectedHoney? selectedHoney : selectedItem}</div>
              </div>
                <IoMdArrowDropdown />
              </div>
            </div>
            {isOpenMenu && data && (
  <div ref={submenuRef} className="sub-menu">
    {data.categories.map((category) => (
      <div key={category._id} className="icon-box df" onClick={() => handleItemClick(category.name)}>
        <img src={category.icon[0].url} style={{ height: '2rem', width: '2rem', objectFit: 'cover' }} />
        <div className="text">
          <div className="title">
            {category.name} <i className="far fa-arrow-right"></i>
          </div>
          {/* <div className="sub-text">
            {category.name} هو نوع من العسل ذو خصائص فريدة.
          </div> */}
        </div>
      </div>
    ))}
  </div>
)}
        </div>
      </div>
      {auth &&<div className="nav-icon cursor-pointer log-tf-out" onClick={handleLogOut}><Logout /></div>}
      <Link to='/' className='sdnone m-d-none'>
        <img src="/img/logo.png" alt="" className='m-d-none' srcSet="" />
      </Link>
      <ul className={` links df pr !gap-4 ${isOpen ? "opened" : ""}`}>
        
        <NavLink to="/" className="df"><div className="nav-icon"><HomeOutlined /></div> الصفحة الرئيسية</NavLink>
        <NavLink to="/team" className='df bordered'><div className="nav-icon"><FaUserGroup /></div> سُفَراؤنا</NavLink>
        <NavLink to="/faq" className='df bordered'><div className="nav-icon"><Chat /></div> تواصل </NavLink>
        <NavLink to="/honey-products" className='df bordered'><div className="nav-icon"><Chat /></div> منتجات العسل</NavLink>
        {auth &&<div onClick={handleLogOut} className='df cursor-pointer bordered'><div className="nav-icon"><Login /></div> <LogoutOutlined  /></div>}

          

      </ul>
      
      
    </nav>
    <ul className={`phone-menu links jc-sa df !gap-8`}>
          
        <NavLink to="/" className="df-c !items-center"><div className="nav-icon"><HomeOutlined /></div> <div className='ta-c'> الرئيسية</div> </NavLink>
        <NavLink to="/team" className='df-c !items-center'><div className="nav-icon"><FaUserGroup /></div>  <div  className='ta-c'>فريقنا </div> </NavLink>
        <NavLink to="/faq" className='df-c !items-center'><div className="nav-icon"><Chat /></div>  <div  className='ta-c'> تواصل </div></NavLink>
        <NavLink to="/honey-products" className='df-c !items-center'><div className="nav-icon"><HiveRounded /></div>  <div  className='ta-c'>منتجات  </div> </NavLink>

        
            {!auth &&
            <div className="df-c !items-center" onClick={()=>{open()}}><div className="nav-icon"><Login /></div> </div>
          }
          {auth && <NavLink to="/profile" className="!w-fit sm-min df comment-user-img">
            <img
              src={`${auth?.user?.image? auth?.user?.image : "/img/default.png"}`}
              className={`!w-12 !h-12`}
              alt="user"
            />
          </NavLink>}
         

      </ul>
    </>
  );
}

export default Navbar;