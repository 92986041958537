import { useEffect, useState } from "react";
import Authentification from "../../components/Modal/Authentification";
import ModalContainer from "../../components/Modal/ModalContainer";
import useModal from "../../hooks/useModal";
import "./checkout.css";
import { GiCheckMark } from "react-icons/gi";
import { IoMdStar } from "react-icons/io";
import { GoComment, GoTrash } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { Add, Delete, PhoneAndroid } from "@mui/icons-material";
import { useCartContext } from "../../hooks/useCartContext";
import Empty from "../../components/empty/Empty";
import { useAuthContext } from "../../hooks/useAuthContext";
import useDeleteData from "../../hooks/useDeleteData";
import AddAdress from "../../components/Modal/AddAdress";
import OrderCoupon from "../../components/OrderCoupon";
import axios from "axios";
import { useFetchData } from "../../hooks/useFetchData";
import SuccessNotification from "../../components/notifications/SuccessNotification";
import { productReview } from "../../utils/functions";
import { CiFacebook, CiInstagram, CiLinkedin, CiTwitter } from "react-icons/ci";
import { CgWebsite } from "react-icons/cg";
import { useSettingsContext } from "../../hooks/useSettingsContext";


function getSocialMediaIcon(name) {
  const socialMediaIcons = {
    facebook: <CiFacebook />,
    twitter: <CiTwitter />,
    instagram: <CiInstagram />,
    linkedin: <CiLinkedin />,
  };
  const lowerName = name.toLowerCase();
  return socialMediaIcons[lowerName] || <CgWebsite />;
}
function Checkout() {
  const { data: shipping } = useFetchData("client-api/shipping");
  const { data: bankInfo } = useFetchData("client-api/terms");
  const {settings} = useSettingsContext();
  const storedUserData = JSON.parse(localStorage.getItem('auth'));
  const token = storedUserData?.token;  // Extract the token from localStorage

  
  
  
  const { auth, dispatch: disuser } = useAuthContext();
  const { deleteData, loading, data: deleteResponse, error } = useDeleteData();
  const [selectedPayment, setSelectedPayment] = useState(""); 
  const [phone, setPhone] = useState(auth?.user?.phone || '');
  const [couponAmount, setCouponAmount] = useState("");
  const [address, setAddress] = useState();
  const [billing, setBilling] = useState();
  const [formData, setFormData] = useState({});
  const { data: mandoubs } = useFetchData(
    "mandoub/staff?area=" + address?.state
  );
  
  const [selectedMandoub, setSelectedMandoub] = useState((mandoubs? mandoubs[0] : ""));
  const [bankProof, setBankProof] = useState(null);
  const [errorO, setError] = useState(false);
  const [loadingState, setLoading] = useState(false);
  const [showSuccessNotification, setshowSuccessNotification] = useState(false);
  const navigate = useNavigate();
  const handleshowSuccessNotification = () => {
    setshowSuccessNotification(true);
    setTimeout(() => setshowSuccessNotification(false), 10000);
  };
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setBankProof(e.target.files[0]);
    }
  };
  
  useEffect(() => {
    if ((!phone || phone === '') && auth?.user?.phone) {
      setPhone(auth.user.phone);
    }
  }, [auth?.user?.phone, phone]);
  
  const handleDelete = (id) => {
    deleteData(`client-api/adress?id=${id}`);
  };
  
  console.log("===================bank info ", bankInfo);
  
  useEffect(() => {
    console.log(couponAmount);
  }, [couponAmount]);
  
  const handleSelect = (value) => {
    setSelectedPayment(value); // This will handle payment method selection only
  };
  const handleSelectMandoub = (value) => {
    setSelectedMandoub(value); // This will handle Mandoub selection only
  };
  
  useEffect(() => {
    if (deleteResponse) {
      const { updatedUser } = deleteResponse;
      if (updatedUser) {
        disuser({ type: "LOGIN", payload: { ...auth, user: updatedUser } });
      }
    }
  }, [deleteResponse]);
  
  const { modalOpen, close, open } = useModal();
  const { cart, dispatch } = useCartContext();
  
  const totalPrice = cart?.reduce((total, product) => {
    if (!product.isGift) {
      // Only include products where isGift is false or not defined
      const price = product.discount ? product.discount : product.price;
      const quantity = product.qteToBuy > 0 ? product.qteToBuy : 0; // Prevent multiplication by zero quantity
      return total + price * quantity;
    }
    return total;
  }, 0);
  const TotalKG = cart?.reduce((total, product) => {
    if (
      product.categories &&
      product.categories[0] &&
      !product.isGift &&
      product.categories[0] !== "منتجات العسل"
    ) {
      const unitValue = +product?.unitValue;
      return total + (unitValue ? unitValue * (product.qteToBuy || 0) : 0);
    }
    return total;
  }, 0);
  
  useEffect(() => {
    setFormData({
      coupon: {
        discount: +couponAmount.amount,
      },
      couponData: couponAmount,
      products: [cart],
      billingInfo: billing,
      shippingInfo: address,
      staff: selectedMandoub._id ? selectedMandoub._id : null,
      deliveryInfo: {
        cost: shipping?.shippingCharges?.internationalCost,
        method: "Standard",
      },
      paymentData: {
        method: selectedPayment,
      },
      totalPrice: (totalPrice + shipping?.shippingCharges?.internationalCost).toFixed(2),
      payAmount: couponAmount.amount
      ? (
        (totalPrice + shipping?.shippingCharges?.internationalCost) *
        (1 - parseFloat(couponAmount.amount) / 100)
      ).toFixed(2)
      : (totalPrice + shipping?.shippingCharges?.internationalCost).toFixed(2),
        vat: 0,
        tax: 0,
      });
    console.log(formData);
  }, [
    couponAmount,
    cart,
    billing,
    selectedMandoub,
    address,
    selectedPayment,
    totalPrice,
  ]);
  
  const giftCount = cart.filter((product) => product.isGift).length;
  
  const handleCheckout = async () => {
    console.log(token);
    setLoading(true)
    const formDataObj = new FormData();
    
    // Convert the entire order data to a single JSON string
    const orderData = {
      coupon: formData.coupon,
      products: cart, // Send cart directly instead of wrapping in array
      billingInfo: billing,
      shippingInfo: address,
      staff: selectedMandoub?._id || "",
      phone: phone,
      deliveryInfo: {
        cost: shipping?.shippingCharges?.internationalCost,
        method: "Standard",
      },
      paymentData: {
        method: selectedPayment,
      },
      totalPrice: parseFloat((totalPrice + shipping?.shippingCharges?.internationalCost).toFixed(2)),
      payAmount: couponAmount.amount
      ? parseFloat(
        (
          (totalPrice + shipping?.shippingCharges?.internationalCost) *
          (1 - parseFloat(couponAmount.amount) / 100)
        ).toFixed(2)
      )
      : parseFloat((totalPrice + shipping?.shippingCharges?.internationalCost).toFixed(2)),
      vat: 0,
      tax: 0,
    };
    
    // Append the order data as a JSON string
    formDataObj.append("orderData", JSON.stringify(orderData));
    
    // Append bank proof if present
    if (selectedPayment === "bank" && bankProof) {
      formDataObj.append("bankProof", bankProof);
    }
    
    try {
      // Handle coupon validation if needed
      if (couponAmount && couponAmount.code) {
        const couponResponse = await axios.post(
          `${
            process.env.REACT_APP_API_URL
          }client-api/applyCoupon?couponCode=${encodeURIComponent(
            couponAmount.code
          )}`,
          {},
          {
            headers: {"Authorization": `Bearer ${token}`, },
            withCredentials: true,
          }
        );
        
        if (!couponResponse.data.success) {
          throw new Error("Coupon validation failed");
        }
      }
      
      // Submit order
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}client-api/order`,
        formDataObj,
        {
          headers: { "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`,  },
          withCredentials: true,
        }
      );

      if (orderResponse.data.success) {
        setAddress("");
        setBilling("");
        navigate(`/thanks/${orderResponse.data.createdOrder._id}`);
        dispatch({ type: "EMPTY_CART" });
        handleshowSuccessNotification();
        setLoading(false)
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      setError(error?.data?.error.message || "something went wrong");
      setLoading(false)
    }
  };
  
  return (
    <>
      {auth && (
        <div className="container-padding mt-5">
          <ModalContainer>
            {modalOpen && (
              <AddAdress modalOpen={modalOpen} handleClose={close} />
            )}
          </ModalContainer>
          {showSuccessNotification && (
            <SuccessNotification
              message="order created succefully"
              img={"/img/success.png"}
              onClose={() => setshowSuccessNotification(false)}
            />
          )}

          {cart.length > 0 && (
            <div className="df-c checkch">
              <div className="col-12 col-md-10 !bg-white hh-grayBox pb20 !mx-auto scrolable !w-screen">
              <div className={`trackcont !bg-white p-5 Pending`}>
  <div className={`order-tracking ${phone ? 'completed' : ''}`}>
    <span className="is-complete">1</span>
    <p>{phone ? 'تم تأكيد رقم الهاتف' : 'رقم الهاتف'}<br /></p>
  </div>
  <div className={`order-tracking ${(phone && address) ? 'completed' : ''}`}>
    <span className="is-complete">2</span>
    <p>{phone && address ? 'تم تحديد معلومات الشحن' : 'معلومات الشحن'}<br /></p>
  </div>
  <div className={`order-tracking ${(phone && address && billing) ? 'completed' : ''}`}>
    <span className="is-complete">3</span>
    <p>{(phone && address && billing) ? 'تم تأكيد العنوان' : 'تأكيد العنوان'}<br /></p>
  </div>
  <div className={`order-tracking ${(phone && address && billing && selectedPayment) ? 'completed' : ''}`}>
    <span className="is-complete">4</span>
    <p>{phone && address && billing && selectedPayment ? 'تم اختيار طريقة الدفع' : 'طرق الدفع'}<br /></p>
  </div>
  <div className={`order-tracking ${(phone && address && billing && selectedPayment && selectedMandoub) ? 'completed' : ''}`}>
    <span className="is-complete">5</span>
    <p>{phone && address && billing && selectedPayment && selectedMandoub ? 'تم اختيار المندوب' : 'اختيار المندوب'}<br /></p>
  </div>
</div>
            </div>

            <div className="df w-full sm-df-c ai-stretch checkout">
              <div className="df-c !w-1/2 sm-w-full">
                <div className="df-c !gap-12 contact p-4 bg-white">
                  <div className="title" onClick={open}>
                    رقم الهاتف
                  </div>
                  <div className="df pr">
                    <input
                      type="text"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      
                      }}
                      className="input ser-in !w-full"
                    />
                    <PhoneAndroid className="ser" />
                  </div>
                </div>
                {phone &&<div className={`df-c indic`}>
                  <div className="df-c bg-white p-4">
                    <div className="df jc-sb">
                      <div className="title2 ">معلومات الشحن</div>
                      <div onClick={open}><Add /></div>
                    </div>
                    <div className="df ms-df-c flex-wrap mt-8">
                      {auth?.user.address
                        .filter(
                          (element) => element.addressType === "عنوان الشحن"
                        )
                        .map((element) => (
                          <label
                            htmlFor={element?._id}
                            className={`adressbox df-c justify-center ${
                              address?._id === element?._id ? "selected" : ""
                            }`}
                            key={element?._id}
                          >
                            <div className="df-c">
                              <div className="title">{element.name} </div>
                              <p>
                                {element.city}, {element.state}, {element.house}
                                , {element.zipCode}, {element.country}
                              </p>
                              <button
                                className="poubelle"
                                onClick={() => handleDelete(element._id)}
                              >
                                <Delete />
                              </button>
                            </div>
                            <input
                              type="radio"
                              name="adress"
                              className="!mx-auto"
                              id={element?._id}
                              value={element}
                              onChange={() => setAddress(element)}
                              checked={element?._id === address?._id}
                            />
                          </label>
                        ))}
                    </div>
                  </div>
                </div>}

                {phone && address &&<div className={`df-c mt-8 indic`}>
                  <span className="filledindic"></span>
                  <div className="df-c bg-white p-4">
                    <div className="df jc-sb">
                      <div className="title2 "> عنوان الفواتير </div>
                      <div onClick={open}><Add /></div>
                    </div>
                    <div className="df sm-df-c flex-wrap mt-8">
                      {auth?.user.address
                        .filter(
                          (element) => element.addressType === "عنوان الفواتير"
                        )
                        .map((element) => (
                          <label
                            htmlFor={element?._id}
                            className={`adressbox df-c justify-center ${
                              billing?._id === element?._id ? "selected" : ""
                            }`}
                            key={element?._id}
                          >
                            <div className="df-c">
                              <div className="title">{element.name} </div>
                              <p>
                                {element.city}, {element.state}, {element.house}
                                , {element.zipCode}, {element.country}
                              </p>
                              <button
                                className="poubelle"
                                onClick={() => handleDelete(element._id)}
                              >
                                <Delete />
                              </button>
                            </div>
                            <input
                              type="radio"
                              name="billing"
                              className="!mx-auto"
                              id={element?._id}
                              value={element}
                              onChange={() => setBilling(element)}
                              checked={element?._id === billing?._id}
                            />
                          </label>
                        ))}
                    </div>
                  </div>
                </div>}

                {phone && address && billing&&<div
                  className={`df-c mt-8 indic ${
                    selectedPayment ? "filled" : ""
                  }`}
                >
                  <span className="filledindic"></span>
                  <div className="title2">طرق الدفع</div>
                  <div className="p-8 w-full ">
                    <div className="df sm-df-c wrap mt-4 w-full justify-around">
                      <label
                        htmlFor="payment1"
                        className={`df-c payment-label ${
                          selectedPayment === "نقداً" ? "selected" : ""
                        }`}
                      >
                        <div className="payment-card !w-44 !h-28">
                          <img src="/img/cash.jpg" alt="نقداً" />
                        </div>
                        <span className="ta-c font-bold text-sm ">نقداً</span>
                        <input
                          type="radio"
                          name="payment"
                          id="payment1"
                          value="نقداً"
                          onChange={() => handleSelect("نقداً")}
                          checked={selectedPayment === "نقداً"}
                        />
                      </label>

                      <label
                        htmlFor="payment2"
                        className={`df-c payment-label ${
                          selectedPayment === "bank" ? "selected" : ""
                        }`}
                      >
                        <div className="payment-card !w-44 !h-28">
                          <img src="/img/mastercard.png" alt="ماستر كارد" />
                        </div>
                        <span className="ta-c font-bold text-sm">bank </span>
                        <input
                          type="radio"
                          name="payment"
                          id="payment2"
                          value="bank"
                          onChange={() => handleSelect("bank")}
                          checked={selectedPayment === "bank"}
                        />
                      </label>
                    </div>
                  </div>
                </div>}
                {selectedPayment === "bank" && bankInfo && phone && address && billing && (
                  <div
                    className="p-4 bg-white"
                    dangerouslySetInnerHTML={{
                      __html: bankInfo?.webpageData[0]?.termsPage.content,
                    }}
                  />
                )}

                {selectedPayment === "bank" && (
                  <div className="df-c p-4 bg-white mt-4">
                    <label className="mb-2 font-bold">
                      إثبات الدفع المصرفي
                    </label>
                    <input
                      type="file"
                      name="bankProof"
                      onChange={handleFileChange}
                      accept="image/*"
                      className="w-full p-2 border rounded"
                    />
                  </div>
                )}
              </div>
              <div className="flex1">
                <div className="df ta-c men font-bold">
                  <div className="product flex1 ">المنتج</div>
                  <div className="qte w-1/5">الكمية</div>
                  <div className="price w-1/5">السعر</div>
                </div>
                <div className="cardcontent !h-auto">
                {cart?.map(product => {
  const { averageRating } = productReview(product.review);
  const isVariableType = product.type === "variable";

  return (
    <div 
      key={product.id} 
      className="product-cart bg-white p-4" 
    >
      <div className="product-img">
        {product.isGift && <img src="/img/gift.png" alt="" className="gifted" />}
        <img src="/img/polygon.png" alt="" className="poly" />
        <img 
          src={product.isGift ? product.img : (Array.isArray(product?.image) && product?.image[0]?.url ? product?.image[0]?.url : '/img/default.png')}
          alt={product.name}
          className="product-cart-img"
        />
      </div>
      <div className="details-cart-prod flex1">
        <div className="font-bold">{product.name}</div>
        {product.unit === "كغ" && (
          <div className="df sm-w-fit sm-mx-auto">
            <div className="desc-cart !h-auto">الكمية الإجمالية :</div>
            <div className="prodPr">كغ {(parseFloat(product.unitValue) * product.qteToBuy).toFixed(2)}</div>
          </div>
        )}
        {product?.isGift && (
          <div className="status paid">مستوى الهدية : {product?.giftLevel || 'N/A'}</div>
        )}
        <div className="df mt-2">
          <div className="prodPr">{settings?.currency.name}{ product?.discount?.toFixed(2)}</div> -
          {!product?.isGift && 
            <div className="rate df !gap-1">
              {averageRating ? averageRating : 0}
              <IoMdStar className="!text-white" />
            </div>
          }
        </div>
        {isVariableType && !product.isGift && (
          <div className="df mt-2">
            <div className="prodPr">
              <div 
                style={{ background: product.selectedColor?.value }} 
                className="color"
              ></div>
            </div> 
            <div className="sizes df !gap-1">
              <div className="size">{product.selectedAttribute || 'N/A'} </div>
            </div>
          </div>
        )}
      </div>
      <div className="but w-1/5 ta-c">
        {!product.isGift && (
          <div className="df-c">
            {!product.isFilled && (
              <div className="df">
                <div className="contr">
                  <span onClick={() => dispatch({ type: "INCREASE_QUANTITY", payload: product })}>+</span>
                </div>
                <span className="font-bold">{product.qteToBuy}</span>
                <div className="contr">
                  <span onClick={() => dispatch({ type: "DECREASE_QUANTITY", payload: product })}>-</span>
                </div>
              </div>
            )}
            <span className="df justify-center cursor-pointer" onClick={() => dispatch({ type: "REMOVE_PRODUCT", payload: product })}>
              <GoTrash /> إزالة
            </span>
          </div>
        )}
        {product.isGift && <span className="font-bold">1</span>}
      </div>
      <div className="but w-1/5 ta-c">
        <div className="prodPr">{!product.isGift ? ( product.price * product.qteToBuy).toFixed(2) : "مجاني"}</div>
      </div>
    </div>
  );
})}
                  <div className="df ta-c men w-full mr-auto font-bold">
                    <div className="product flex1 ta-c">المنتج</div>
                  </div>
                  <div className="df jc-sb sub-bill">
                    <div>إجمالي الكيلوغرام</div>
                    <div>
                      <div className="prodPr">كغ{TotalKG.toFixed(2)}</div>
                    </div>
                  </div>
                  <div className="df jc-sb ">
                    <div>المجموع الفرعي</div>
                    <div>
                      <div className="prodPr">ر.س{totalPrice.toFixed(2)}</div>
                    </div>
                  </div>
                  {giftCount > 0 && (
                    <div className="df jc-sb sub-bill">
                      <div>الهدايا</div>
                      <div>
                        <div className="prodPr">x {giftCount}</div>
                      </div>
                    </div>
                  )}
                  <div className="df jc-sb">
                    <div>رسوم الشحن</div>
                    <div>
                      <div className="prodPr">{shipping?.shippingCharges?.internationalCost}</div>
                    </div>
                  </div>
                  <OrderCoupon setCouponAmount={setCouponAmount} />

                  <div className="df jc-sb">
                    <div>المجموع الكلي</div>
                    <div>
                      {!couponAmount && (
                        <div className="prodPr">{`${(totalPrice + shipping?.shippingCharges?.internationalCost).toFixed(
                          2
                        )}`}</div>
                      )}
                      {couponAmount && (
                        <div className="prodPr">{`${(
                          (totalPrice + shipping?.shippingCharges?.internationalCost) *
                          (1 - parseFloat(couponAmount.amount) / 100)
                        ).toFixed(2)}`}</div>
                      )}
                    </div>
                  </div>
                </div>
                {mandoubs?.mandoubs && mandoubs?.mandoubs.length > 0 ? (
                  <>
                    <div className="title2 mt-12">اختر مندوبك المفضل</div>
                    <div className="df-c mandoub-list mt-8">
                      {mandoubs.mandoubs.map((mandoub, index) => (
                        <label
                          key={mandoub._id}
                          htmlFor={`${mandoub._id}`}
                          className={`mandoub df sm-df-c sm-items-center ai-stretch payment-label ${
                            selectedMandoub._id === `${mandoub._id}`
                              ? "selected"
                              : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="mandoub"
                            id={mandoub._id}
                            value={mandoub}
                            className="dnone"
                            onChange={() => handleSelectMandoub(mandoub)}
                            checked={selectedMandoub._id === mandoub._id}
                          />
                          <div className="mandoub-img">
                            <img
                              src={
                                mandoub.img
                                  ? process.env.REACT_APP_UPLOADS_DIR +
                                    mandoub.img
                                  : "/img/default.png"
                              } // Replace with actual image URL if available
                              alt={mandoub.fullName}
                            />
                          </div>
                          <div className="flex1 pr py-1">
                            <div className="df sm-justify-center">
                              <div className="font-bold !text-xl">
                                {mandoub.fullName || "اسم المندوب"}
                              </div>
                              <div className="prodPr">#{mandoub.id}</div>
                              <div className="simple-btn !px-4 mr-auto !p-0">
                                انقر للاختيار
                              </div>
                            </div>
                            <div className="df sm-justify-center mt-2">
                              <div className="rate df !gap-1">
                                0 <IoMdStar className="!text-white" />
                              </div>{" "}
                              -{" "}
                              <div className="df !gap-1 text-sm">
                                ({mandoub?.reviews?.length}) <GoComment />
                              </div>{" "}
                             
                            </div>
                            <p className="desc-cart mt-2 sm-text-center !h-auto">
                              {mandoub.area || "لوريم إيبسوم دولور سيت أميت..."}{" "}
                              -{" "}
                              <div className="df med !m-0">
                                {mandoub.contact?.map((c, i) => (
                                  <a
                                    key={i}
                                    href={c.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="social-icon"
                                  >
                                    {getSocialMediaIcon(c.platform)}
                                  </a>
                                ))}
                              </div>
                            </p>

                            <Link
                              to={`/team/${mandoub._id}`}
                              className="sm-dis-inline-link link-sec sm-text-center sm-mx-auto"
                            >
                              معرفة المزيد عن هذا المندوب
                            </Link>
                          </div>
                        </label>
                      ))}
                    </div>
                  </>
                ) : phone && address && billing && selectedPayment ?(
                  <div className="df-c">
                    <div className="df-c mandoub-list mt-8">
                    <div className="mandoub items-center df sm-df-c sm-items-center payment-label selected">
  <div className="mandoub-img">
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrrezqcO2ovuXx11B-3Uzx1F9IyTrYvOF0oA&s"
      alt="خدمة التوصيل"
    />
  </div>
  <div className="flex1 df-c h-full justify-center !gap-1 pr py-1">
    <div className="df sm-justify-center">
      <div className="font-bold !text-xl">
      التوصيل المنزلي      </div>
      <div className="prodPr">{shipping?.shippingCharges?.internationalCost} {settings?.currency.name}</div>
    </div>
    <p className="desc-cart mt-2 sm-text-center !h-auto">
      توصيل لكافة مناطق الجمهورية اليمنية
    </p>
    <Link to="/terms" className="sm-dis-inline-link link-sec sm-text-center sm-mx-auto">
      معرفة المزيد عن شروط وسياسة التوصيل
    </Link>
  </div>
</div>
</div>
                  <button
                      disabled={loadingState}
                      onClick={handleCheckout}
                      className="btn sm-w-full sm-justify-sb mr-auto mt-10"
                    >
                      {loadingState ? "loading ..." : "إرسال"}
                      
                      <div className="ic ">
                        <GiCheckMark />
                      </div>
                    </button>
                  </div>
                ) : (<div></div>)}
                {errorO && <div>{errorO}</div>}
                {selectedMandoub &&
                  mandoubs?.mandoubs &&
                  mandoubs?.mandoubs.length > 0 && (
                    <button
                      disabled={loadingState}
                      onClick={handleCheckout}
                      className="btn sm-w-full sm-justify-sb mr-auto mt-10"
                    >
                      {loadingState ? "loading ..." : "إرسال"}
                      
                      <div className="ic ">
                        <GiCheckMark />
                      </div>
                    </button>
                  )}
              </div>
            </div>
            </div>
          )}
          {cart.length === 0 && <Empty />}
        </div>
      )}
      {!auth && (
        <div className="w-fit mt-20 df mx-auto">
          <ModalContainer>
            {modalOpen && (
              <Authentification modalOpen={modalOpen} handleClose={close} />
            )}
          </ModalContainer>
          <Empty message="يجب تسجيل الدخول أولا" />{" "}
          <div className="simple-btn" onClick={open}>
            الدخول
          </div>
        </div>
      )}
    </>
  );
}

export default Checkout;