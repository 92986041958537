
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import ImageMagnifier from "../img magnifier/ImageMagnifier";
import { useEffect, useRef } from "react";

function ProductImages({ imagesUrl, mainImg, index }) {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  }, [index]);

  return (
    <div className="df-c">

    <Swiper
      ref={swiperRef}
      navigation={true}
      loop={true}
      centeredSlides={true}
      modules={[Navigation]}
      className="mySwiper"
      
    >
      
      {imagesUrl.slice().reverse().map((element, idx) => (
        <SwiperSlide key={idx}>
          <ImageMagnifier
            src={element.url}
            width={300}
            height={200}
            magnifierHeight={500}
            magnifierWidth={500}
            zoomLevel={2}
            alt="galerie"
          />
        </SwiperSlide>
      ))}
      <SwiperSlide key={imagesUrl.length}>
        <ImageMagnifier
          src={mainImg}
          width={300}
          height={200}
          magnifierHeight={500}
          magnifierWidth={500}
          zoomLevel={2}
          alt="galerie"
          
        />
      </SwiperSlide>
    </Swiper>
    <h1>{index}</h1>
    </div>
  );
}

export default ProductImages;
