import { useState } from "react";
import OrdersContent from "../../components/orders content/OrdersContent";
import { useFetchData } from "../../hooks/useFetchData";
import Empty from "../../components/empty/Empty";
import { useSettingsContext } from "../../hooks/useSettingsContext";

function Orders() {
    const [selectedOrder, setSelectedOrder] = useState('');
    const { data, loading, error } = useFetchData("client-api/order");
     const {settings} = useSettingsContext();

    return (
        <div className="df sm-df-c ai-stretch">
            <div className="bg-white sm-w-full df-c p-4 w-4/12 orders">
                <div className="title mb-12">طلباتي</div>

                {
                    data && data.orders.map((order) => (
                        <div className={`order-box df-c p-4 {settings?.currency.name}{order._id === selectedOrder._id ? 'selected' : ''}`} onClick={() => setSelectedOrder(order)} key={order._id}>
                            <div className="df jc-sb">
                                <div className="font-bold w-20 overflow-auto">طلب {order.orderId}</div>
                                {order.status === "Pending" &&
                                    <div className="status pending">جارٍ معالجة الطلب</div>
                                }
                                {order.status === "In Progress" &&
                                    <div className="status pending">جارٍ معالجة الطلب</div>
                                }
                                {order.status === "Canceled" &&
                                    <div className="status failed">ملغى</div>
                                }
                                {order.status === "Delivered" &&
                                    <div className="status paid">تم التوصيل</div>
                                }
                            </div>
                            <div className="df-c jc-sb">
                                <div className="font-bold">تاريخ الطلب :</div>
                                <div className="">{new Date(order.orderDate).toLocaleString('ar-EG', { weekday: 'long', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</div>
                            </div>
                            <div className="df jc-sb">
                                <div className="font-bold">الشحن :</div>
                                <div className="">{order.deliveryInfo.cost}{settings?.currency.name}</div>
                            </div>
                            <div className="df jc-sb">
                                <div className="font-bold">الإجمالي :</div>
                                <div className="">{order.payAmount}{settings?.currency.name}</div>
                            </div>
                        </div>
                    ))
                }
                {
                    loading &&
                    <Empty message={"جار التحميل ..."} />
                }
            </div>
            <OrdersContent selectedOrder={selectedOrder} />
        </div>
    )
}

export default Orders;
