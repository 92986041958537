import "./loading.css";

function ErrorPage() {
    const handleReload = () => {
        window.location.reload();
    };

    return (
        <div className="error-page">
            <div className="df-c items-center">
                <div className="bee-container">
                    <div className="wing-container">
                        <div className="wing" id="wing1"></div>
                        <div className="wing" id="wing2"></div>
                    </div>
                    <div className="face-container">
                        <div className="eye" id="left"></div>
                        <div className="eye" id="right"></div>
                        <div className="smile"></div>
                    </div>
                    <div className="beeLoader">
                        <div className="shadow"></div>
                        <div className="stripe" id="one"></div>
                        <div className="stripe" id="two"></div>
                        <div className="stripe" id="three"></div>
                        <div className="shine"></div>
                    </div>
                    <div className="leg-container">
                        <div className="leg" id="leg1"></div>
                        <div className="leg" id="leg2"></div>
                    </div>
                </div>
                <div className="title df">
                <div className="simple-btn" onClick={handleReload}>إعادة تحميل الصفحة</div> حدث خطأ ما 
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;