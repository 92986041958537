import { GiBee, GiStorkDelivery } from "react-icons/gi";
import "./card.css";
import { LuShoppingBasket } from "react-icons/lu";
import { useEffect, useState } from "react";
import { IoMdStar } from "react-icons/io";
import { GoTrash } from "react-icons/go";
import { Link } from "react-router-dom";
import { useCartContext } from "../../hooks/useCartContext";
import Empty from "../empty/Empty";
import { motion, AnimatePresence } from 'framer-motion';
import SuccessNotification from "../notifications/SuccessNotification";
import WarningNotification from "../notifications/WarningNotification";
import ErrorNotification from "../notifications/ErrorNotification";
import { productReview } from "../../utils/functions";
import { useFetchData } from "../../hooks/useFetchData";
import { useSettingsContext } from "../../hooks/useSettingsContext";

function Cart() {
  const itemVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50, transition: { duration: 0.5, ease: "easeInOut" } },
  };
  
  const [showSuccessNotification, setshowSuccessNotification] = useState(false);
  const [showWarningNotification, setshowWarningNotification] = useState(false);
  const [showErrorNotification, setshowErrorNotification] = useState(false);
  const {settings} = useSettingsContext();
  const [warningMessage, setwarningMessage] = useState("");
  const [giftsData, setGiftsData] = useState(null);
  const { data } = useFetchData("client-api/gifts");
  const [isOpen, setIsOpen] = useState(false);
  const { cart, dispatch } = useCartContext();

  useEffect(() => {
    if (data?.giftsData) {
      setGiftsData(data.giftsData);
    }
  }, [data]);

  const handleshowSuccessNotification = () => {
    setshowWarningNotification(false);
    setshowErrorNotification(false);
    setshowSuccessNotification(true);
    setTimeout(() => setshowSuccessNotification(false), 10000);
  };

  const handleshowWarningNotification = () => {
    setshowSuccessNotification(false);
    setshowErrorNotification(false);
    setshowWarningNotification(true);
    setTimeout(() => setshowWarningNotification(false), 10000);
  };

  const handleshowErrorNotification = () => {
    setshowWarningNotification(false);
    setshowSuccessNotification(false);
    setshowErrorNotification(true);
    setTimeout(() => setshowErrorNotification(false), 10000);
  };

  const totalPrice = cart?.reduce((total, product) => {
    if (!product.isGift) {
      const price = product.discount ? product.discount : product.price;
      const quantity = product.qteToBuy > 0 ? product.qteToBuy : 0;
      return total + price * quantity;
    }
    return total;
  }, 0);

  const TotalKG = cart?.reduce((total, product) => {
    if ((product.categories && product.categories[0]) && (!product.isGift && product.categories[0] !== "منتجات العسل")) {
      const unitValue = +product?.unitValue;
      return total + (unitValue ? unitValue * (product.qteToBuy || 0) : 0);
    }
    return total;
  }, 0);

  const checkAndUpdateGifts = () => {
    if (!giftsData) return;

    // Check warning thresholds for each level
    Object.entries(giftsData).forEach(([level, giftData]) => {
      if (level.startsWith('level') && giftData.target) {
        const remainingKG = giftData.target - TotalKG;
        if (remainingKG <= 10 && remainingKG > 0) {
          setwarningMessage(`أنت على بعد ${remainingKG.toFixed(2)} كجم من هديتك من المستوى ${giftData.giftLevel}`);
          handleshowWarningNotification();
        }
      }
    });

    // Get current gift in cart if any
    const currentGift = cart.find(item => item.isGift);
    const currentGiftLevel = currentGift?.giftLevel;

    // Determine which gift level should be active based on TotalKG
    let targetGiftLevel = null;
    if (TotalKG >= giftsData.level3.target) {
      targetGiftLevel = 3;
    } else if (TotalKG >= giftsData.level2.target) {
      targetGiftLevel = 2;
    } else if (TotalKG >= giftsData.level1.target) {
      targetGiftLevel = 1;
    }

    // If we need to change the gift
    if (targetGiftLevel !== currentGiftLevel) {
      // Remove current gift if exists
      if (currentGift) {
        dispatch({ type: "REMOVE_PRODUCT", payload: currentGift });
        handleshowErrorNotification();
      }

      // Add new gift if we're above any threshold
      if (targetGiftLevel) {
        const giftToAdd = {
          ...giftsData[`level${targetGiftLevel}`],
          id: `gift-${Date.now()}`
        };
        dispatch({ type: "ADD_GIFT", payload: giftToAdd });
        handleshowSuccessNotification();
      }
    }
  };

  useEffect(() => {
    if (giftsData) {
      checkAndUpdateGifts();
    }
  }, [TotalKG, giftsData]);

  const giftCount = cart?.filter(product => product.isGift).length;

  return (
    <>
      {showSuccessNotification && (
        <SuccessNotification 
          message="تهانينا! لقد حصلت للتو على هدية!" 
          img={"/img/success.png"} 
          onClose={() => setshowSuccessNotification(false)}
        />
      )}
      {showWarningNotification && (
        <WarningNotification 
          message={warningMessage} 
          img={"/img/warning.png"} 
          onClose={() => setshowWarningNotification(false)}
        />
      )}
      {showErrorNotification && (
        <ErrorNotification 
          message={"لقد فقدت هديتك للتو"} 
          img={"/img/error.png"} 
          onClose={() => setshowErrorNotification(false)}
        />
      )}
      <div className="holder mobile-toggler sdblock cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        {cart.length > 0 && <div className="indicat">{cart.length}</div>}
        <div className="toggler">
          <LuShoppingBasket />
        </div>
      </div>
      <div className={`cart sm-w-full ${isOpen ? "toggled" : ""} ${cart.length === 0 ? "df !items-center !justify-center" : ""}`}>
        <div className="holder sdnone cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          {cart.length > 0 && <div className="indicat">{cart.length}</div>}
          <div className="toggler">
            <GiBee />
          </div>
        </div>
        {cart.length > 0 && (
          <>
            <div className="df sdnone ta-c men font-bold">
              <div className="product flex1">المنتج</div>
              <div className="qte w-1/5">الكمية</div>
              <div className="price w-1/5">السعر</div>
            </div>
            <div className="cardcontent">
              <AnimatePresence>
              {cart?.map(product => {
  const { averageRating } = productReview(product.review);
  const isVariableType = product.type === "variable";

  return (
    <motion.div 
      key={product.id} 
      className="product-cart bg-white p-4" 
      variants={itemVariants} 
      initial="hidden" 
      animate="visible" 
      exit="exit" 
      transition={{ duration: 0.4, ease: 'easeInOut' }}
    >
      <div className="product-img">
        {product.isGift && <img src="/img/gift.png" alt="" className="gifted" />}
        <img src="/img/polygon.png" alt="" className="poly" />
        <img 
          src={product.isGift ? product.img : (Array.isArray(product?.image) && product?.image[0]?.url ? product?.image[0]?.url : '/img/default.png')}
          alt={product.name}
          className="product-cart-img"
        />
      </div>
      <div className="details-cart-prod flex1">
        <div className="font-bold">{product.name}</div>
        {product.unit === "كغ" && (
          <div className="df sm-w-fit sm-mx-auto">
            <div className="desc-cart !h-auto">الكمية الإجمالية :</div>
            <div className="prodPr">كغ {(parseFloat(product.unitValue) * product.qteToBuy).toFixed(2)}</div>
          </div>
        )}
        {product?.isGift && (
          <div className="status paid">مستوى الهدية : {product?.giftLevel || 'N/A'}</div>
        )}
        <div className="df mt-2">
          <div className="prodPr">{settings?.currency?.name}{ product?.discount?.toFixed(2)}</div> -
          {!product?.isGift && 
            <div className="rate df !gap-1">
              {averageRating ? averageRating : 0}
              <IoMdStar className="!text-white" />
            </div>
          }
        </div>
        {isVariableType && !product.isGift && (
          <div className="df mt-2">
            <div className="prodPr">
              <div 
                style={{ background: product.selectedColor?.value }} 
                className="color"
              ></div>
            </div> 
            <div className="sizes df !gap-1">
              <div className="size">{product.selectedAttribute || 'N/A'} </div>
            </div>
          </div>
        )}
      </div>
      <div className="but w-1/5 ta-c">
        {!product.isGift && (
          <div className="df-c">
            {!product.isFilled && (
              <div className="df">
                <div className="contr">
                  <span onClick={() => dispatch({ type: "INCREASE_QUANTITY", payload: product })}>+</span>
                </div>
                <span className="font-bold">{product.qteToBuy}</span>
                <div className="contr">
                  <span onClick={() => dispatch({ type: "DECREASE_QUANTITY", payload: product })}>-</span>
                </div>
              </div>
            )}
            <span className="df justify-center cursor-pointer" onClick={() => dispatch({ type: "REMOVE_PRODUCT", payload: product })}>
              <GoTrash /> إزالة
            </span>
          </div>
        )}
        {product.isGift && <span className="font-bold">1</span>}
      </div>
      <div className="but w-1/5 ta-c">
        <div className="prodPr">{!product.isGift ? ( product.price * product.qteToBuy).toFixed(2) : "مجاني"}</div>
      </div>
    </motion.div>
  );
})}

              </AnimatePresence>
              <div className="df ta-c men w-1/2 mr-auto font-bold sub-bill">
                <div className="product flex1 ta-c">المنتج</div>
              </div>
              <div className="df jc-sb w-1/2 mr-auto sub-bill">
                <div>إجمالي الكيلوغرام</div>
                <div>
                  <div className="prodPr">كغ{TotalKG.toFixed(2)}</div>
                </div>
              </div>
              <div className="df jc-sb w-1/2 mr-auto sub-bill">
                <div>الإجمالي</div>
                <div>
                  <div className="prodPr">ر.س{totalPrice.toFixed(2)}</div>
                </div>
              </div>
              {giftCount > 0 && (
                <div className="df jc-sb w-1/2 mr-auto sub-bill">
                  <div>الهدايا</div>
                  <div>
                    <div className="prodPr">x {giftCount}</div>
                  </div>
                </div>
              )}
              <Link to="/checkout" onClick={() => setIsOpen(!isOpen)} className="btn !w-1/2 jc-sb mr-auto sub-bill">
                المتابعة إلى الدفع
                <div className="ic">
                  <GiStorkDelivery />
                </div>
              </Link>
            </div>
          </>
        )}
        {cart.length === 0 && <Empty />}
      </div>
    </>
  );
}

export default Cart;