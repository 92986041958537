import { useFetchData } from "../../hooks/useFetchData";
import Empty from "../../components/empty/Empty";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import axios from "axios";

function Coupans() {
  const { data, loading, error } = useFetchData("client-api/coupan");
  const [coupon, setSelectedCoupon] = useState("");
  const [applyLoading, setApplyLoading] = useState(false);
  const [applyError, setApplyError] = useState(null);
  const { auth,dispatch } = useAuthContext();

  useEffect(() => {
    if (data?.coupons[0]?._id) {
      setSelectedCoupon(data.coupons[0]);
    }
  }, [data]);

  const handleApplyCoupon = async () => {
    if (!coupon._id) return;
    setApplyLoading(true);
    setApplyError(null);

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}client-api/coupan?couponId=${coupon._id}`,{},
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`,
          },
        });
      if(response.data.success) {
        dispatch({type:'LOGIN', payload:{...auth,user: response.data.user}})
      }
      
    } catch (err) {
      setApplyError(err?.response?.data?.message || "Failed to apply the coupon. Please try again.");
      console.log(err);
      
    } finally {
      setApplyLoading(false);
    }
  };

  const currentDate = new Date();
  const fiveDays = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds
  const twentyDays = 20 * 24 * 60 * 60 * 1000; // 20 days in milliseconds

  return (
    <div className="df-c">
      <div className="df-c !gap-8 bg-white p-4 sm-p-0">
        <div className="title pr w-fit">حوّل نقاطك إلى كوبونات<div className="wave"></div></div>
        <div className="df flex-wrap bg-white p-4 sm-w-full">
          {loading && "loading..."}
          {error && !loading && <Empty message="something went wrong" />}
          {data && data.coupons.map((element) => (
            <div className={`p-5 rounded-lg sm-w-full ${coupon._id === element._id ? 'selected' : ''}`} onClick={() => setSelectedCoupon(element)} key={element._id}>
              <div className="coupon sm-w-full">
                <div className="left-cop">
                  <div>استمتع بهديتك</div>
                </div>
                <div className="center-cop">
                  <div>
                    <h2>{element.amount}% خصم</h2>
                    <h3>{element.points} نقاط</h3>
                    <small>صالح حتى {new Date(element.expired).toLocaleDateString('ar-EG', { year: 'numeric', month: 'long', day: 'numeric' })}</small>
                  </div>
                </div>
                <div className="right-cop">
                  <div>87878521112</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="df w-full">
          <div className="df w-1/2 mx-auto">
            <button 
              className="simple-btn !justify-center flex-1 mr-auto" 
              onClick={handleApplyCoupon} 
              disabled={applyLoading}
            >
              {applyLoading ? "Applying..." : "تطبيق"}
            </button>
          </div>
        </div>
        {applyError && <div className="error">{applyError}</div>}
      </div>

      <div className="df-c !gap-8 bg-white p-4">
        <div className="title pr w-fit">كوبوناتك<div className="wave"></div></div>
        <div className="df flex-wrap bg-white p-4">
          {auth && auth?.user.coupon?.filter(coupon => new Date(coupon.expired) > currentDate).map((element) => {
            const expirationDate = new Date(element.expired);
            const timeUntilExpiration = expirationDate - currentDate;
            let couponClass = "available-cp";

            if (timeUntilExpiration <= fiveDays) {
              couponClass = "expired-cp";
            } else if (timeUntilExpiration <= twentyDays) {
              couponClass = "soon-cp";
            }

            return (
              <div className={`coupon sm-w-full ${couponClass}`} key={element._id}>
                <div className="left-cop">
                  <div>استمتع بهديتك</div>
                </div>
                <div className="center-cop">
                  <div>
                    <h2>{element.amount}% خصم</h2>
                    <h3>{element.code}</h3>
                    <small>صالح حتى {expirationDate.toLocaleDateString('ar-EG', { year: 'numeric', month: 'long', day: 'numeric' })}</small>
                  </div>
                </div>
                <div className="right-cop">
                  <div>87878521112</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Coupans;
