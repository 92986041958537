import { useState } from 'react';
import Backdrop from '../Backdrop';
import { motion, AnimatePresence } from 'framer-motion';
import { dropIn } from '../../utils/modals';
import SignUpForm from '../auth/SignUpForm';
import LoginForm from '../auth/LoginForm';
import ForgetPasswordForm from '../auth/ForgetPasswordForm';
import { Close } from '@mui/icons-material';

function Authentication({ handleClose, top }) {
  // Use formType instead of showLogin to handle three states
  const [formType, setFormType] = useState('login');

  const toggleForm = (type) => {
    setFormType(type);
  };

  const renderForm = () => {
    switch (formType) {
      case 'login':
        return (
          <motion.div
            key="login"
            initial={{ opacity: 0, x: -100, height: "50%" }}
            animate={{ opacity: 1, x: 0, height: "auto" }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.3 }}
          >
            <LoginForm 
              toggleForm={() => toggleForm('signup')}
              toggleForgotPassword={() => toggleForm('forgot')}
              handleClose={handleClose} 
            />
          </motion.div>
        );
      case 'signup':
        return (
          <motion.div
            key="signup"
            initial={{ opacity: 0, x: 100, height: "50%" }}
            animate={{ opacity: 1, x: 0, height: "auto" }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <SignUpForm 
              toggleForm={() => toggleForm('login')}
              handleClose={handleClose} 
            />
          </motion.div>
        );
      case 'forgot':
        return (
          <motion.div
            key="forgot"
            initial={{ opacity: 0, x: 100, height: "50%" }}
            animate={{ opacity: 1, x: 0, height: "auto" }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            <ForgetPasswordForm 
              toggleForm={() => toggleForm('login')}
              handleClose={handleClose}
            />
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <Backdrop onClick={handleClose} isNotification={true}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={`modal g0 ${top ? '!mt-16' : ''}`}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{
          top: '50%',
          left: '50%',
          width: '40%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '15px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
        }}
      >
        <div className="w-full" onClick={handleClose}>
          <Close className="cursor-pointer" />
        </div>
        <AnimatePresence mode="wait">
          {renderForm()}
        </AnimatePresence>
      </motion.div>
    </Backdrop>
  );
}

export default Authentication;