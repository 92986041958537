import './reviews.css';
import Stars from '../../components/stars rate/Stars';
import { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { Box } from '@mui/material';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import Empty from '../empty/Empty';
import usePostData from '../../hooks/usePostData';
import { formatDate } from '../../utils/functions';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Delete } from '@mui/icons-material';
import axios from 'axios';

const labels = {
  0.5: 'سيء جداً',
  1: 'سيء',
  1.5: 'ضعيف',
  2: 'ضعيف+',
  2.5: 'مقبول',
  3: 'مقبول+',
  3.5: 'جيد',
  4: 'جيد+',
  4.5: "ممتاز",
  5: 'ممتاز+',
};


function getLabelText(value) {
  return `${value}`;
}

const ratingColors = {
  5: { light: 'var(--success-light)', dark: 'var(--success)' },
  4: { light: 'var(--cyan-light)', dark: 'var(--cyan)' },
  3: { light: 'var(--purple-light)', dark: 'var(--purple)' },
  2: { light: 'var(--shipped-light)', dark: 'var(--shipped)' },
  1: { light: 'var(--error-light)', dark: 'var(--error)' },
};
function ReviewsPage({id, reviews}) {
  const [value, setValue] = useState(2);
  const {auth} = useAuthContext();
  const [comment, setcomment] = useState("");
  const [reviewsArr, setReviewsArr] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (reviews) {
      setReviewsArr(reviews)
    }
  
    
  }, [reviews])
  
  const [hover, setHover] = useState(-1);
  const { postData, loading, data, error } = usePostData(); 
  const handleSubmit = async (e) => {
    e.preventDefault();

    const uri = 'client-api/product-review?productId='; 
    await postData(uri+id, {comment,rate:value});

    if (error) {
      console.error('Error adding address:', error);
      return;
    }

    if (!loading && !error) {
      setcomment("")
    }
  };

  useEffect(() => {
    if(data?.review) setReviewsArr([data.review,...reviewsArr])
  
    
  }, [data])

  const canReview = auth?.user?.allowedToReviewProducts?.includes(id);

  

  
  const totalReviews = reviewsArr?.length;
  const totalRatings = reviewsArr?.reduce((acc, review) => acc + review.rate, 0);
  const averageRating = totalRatings / totalReviews;

  // Group reviews by rating
  const ratingCounts = [1, 2, 3, 4, 5].map(rating => ({
    rating,
    count: reviewsArr?.filter(review => review?.rate === rating)?.length
  }));

  const handleDeleteReview = async (reviewId, type, targetId) => {

    setIsDeleting(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}client-api/client-rev?reviewId=${reviewId}&type=${type}&targetId=${targetId}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`,
          },
        }
      );

      if (response.data.success) {
        setReviewsArr(reviewsArr.filter((review) => review._id != reviewId))
      }
    } catch (err) {
      console.log(err);
      
    } finally {
      setIsDeleting(false);
    }
  };


  

  return (
    <div className=" reviews">
      <div className="df-c ai-c w-full">

        <div className="df-c section-card comments-upper !gap-6">
          <div className="title">التقييمات</div>
          <div className="df sm-df-c sm-g-3 w-full jc-sb ai-stretch">
            <div className="df-c">
              <div className="bold-1">إجمالي التقييمات</div>
              <div>
                <div className="df sm-justify-center">
                  <div className="bold-1 text-4xl">{totalReviews.toLocaleString('ar-EG')}</div>
                </div>
               
              </div>
            </div>
            <div className="df-c bordered">
              <div className="bold-1">متوسط التقييمات</div>
              <div>
                <div className="df sm-justify-center">
                  <div className="bold-1 text-4xl">{!isNaN(averageRating.toFixed(1))?averageRating.toFixed(1) : 0 }</div>
                  <Stars number={ Math.floor(averageRating)} />
                </div>
              </div>
            </div>
            <div className="df-c">
              <div className="bold-1">إجمالي التقييمات</div>
              <div className="rev-cont sm-items-center">
            {ratingCounts.map(({ rating, count }) => (
              <div className="df avg-star-nbr ai-fe" key={rating}>
                <span className="nbr-indic">{rating}</span>
                <div className="review-line-container" style={{ background: ratingColors[rating].light }}>
                  <div
                    className="line"
                    style={{
                      width: `${(count / totalReviews) * 100}%`,
                      background: ratingColors[rating].dark,
                    }}
                  ></div>
                </div>
                <span>{count}</span>
              </div>
            ))}
          </div>
            </div>
          </div>
        </div>

        {auth && canReview &&<div className="review-form df-c !p-2" id="rate">
          <div className="df-c w-full align-center justify-center">
            {/* <div className="title">كيف كان مستوى الخدمة؟</div>
            <p className='w-1/2 sm-w-full mx-auto'>
              يساعدنا رأيك في تحسين خدماتنا وفهم احتياجاتك بشكل أفضل لضمان تقديم خدمة تلبي توقعاتك.
            </p>  */}
           
  <div className="title">ما رأيك في هذا المنتج؟</div>
  <p className='w-1/2 sm-w-full mx-auto'>
    يساعدنا رأيك في تحسين جودة منتجاتنا وتلبية احتياجاتك بشكل أفضل لضمان رضاك التام عن تجربتك.
  </p>

            <Box className="sm-w-full" sx={{ margin: "2rem auto", display: 'flex', flexDirection: "column", direction: "ltr", alignItems: 'center' }}>

              <Rating
                name="hover-feedback"
                value={value}
                className=''
                precision={0.5}
                getLabelText={getLabelText}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              />
              {value !== null && (
                <Box sx={{ ml: 2 }}> {(hover !== -1 ? hover : value) === 4.5 && (
                  <DotLottieReact
                    src="/icons/happy.json"
                    loop
                    autoplay
                    style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                  />
                )}
                
                {(hover !== -1 ? hover : value) === 5 && (
                  <DotLottieReact
                    src="/icons/love.json"
                    loop
                    autoplay
                    style={{ width: "5rem", height: "5rem", marginTop: "1rem", transform:"scale(1.5)" }}
                  />
                )}
                  {(hover !== -1 ? hover : value) === 0.5 && (
                    <DotLottieReact
                      src="/icons/mad.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 1 && (
                    <DotLottieReact
                      src="/icons/angry.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 1.5 && (
                    <DotLottieReact
                      src="/icons/cry.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 2 && (
                    <DotLottieReact
                      src="/icons/sad.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 2.5 && (
                    <DotLottieReact
                      src="/icons/______.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 3 && (
                    <DotLottieReact
                      src="/icons/___.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 3.5 && (
                    <DotLottieReact
                      src="/icons/____.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                  {(hover !== -1 ? hover : value) === 4 && (
                    <DotLottieReact
                      src="/icons/_____.json"
                      loop
                      autoplay
                      style={{ width: "5rem", height: "5rem", marginTop: "1rem" }}
                    />
                  )}
                </Box>
              )}
            </Box>
            <textarea className='w-50 sm-w-full m-auto max-h-24' value={comment} onChange={(e)=>setcomment(e.target.value)} placeholder='تذكر أن تكون لطيفا' name="" ></textarea>
            {error&& !loading && {error}}
            {data?.message&& data?.message}
            <button disabled={loading} className="simple-btn cursor-pointer justify-center sm-w-full df m-auto mt-5" onClick={(e)=>handleSubmit(e)}>{loading ? "الرجاء الانتظار..." :" إضافة تقييم"}</button>
          </div>
        </div>}


        <div className="comments  !w-4/5 section-card">
          {reviewsArr.length> 0 && reviewsArr?.map((review, index) => (
            <div key={index} className="df sm-items-center sm-df-c comment ai-fs pr gap-8">
              {auth && review.clientId&& review._id&& auth.user._id === review.clientId &&<div onClick={()=>handleDeleteReview(review._id,'product',id)} className="poubelle" >{!isDeleting &&<Delete />} {isDeleting &&"..."}</div>}
              <div className="df comment-user-img ai-stretch">
                <img
                  src={review?.img? review?.img : "/img/default.png"}
                  alt="user"
                />
                <div className="df-c sm-items-center jc-sb flex-1">
                  <div className="bold-1 text-lg">{review.name} {review.surname}</div>
                  <div>
                    <div className="text">{review.orders} طلب</div>
                    
                  </div>
                </div>
              </div>
              <div className="comment-content df-c">
                <div className="text df sm-justify-center text-xs">
                  <Stars number={review.rate} /> {formatDate(review.createdAt)}
                </div>
                <div className="text">{review.comment}</div>
              </div>
            </div>
          ))}
          {
            reviewsArr.length === 0 && <Empty />
          }
        </div>
      </div>
    </div>
  );
}

export default ReviewsPage;
