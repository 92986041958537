import { useState } from 'react';
import axios from 'axios';

const usePutData = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const putData = async (url, updatedData) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.put(process.env.REACT_APP_API_URL+url, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`
        },
        withCredentials:true,
      });
      setData(response.data); 
    } catch (err) {
      setError(err.response ? err.response.data : 'Error occurred while updating data');
    } finally {
      setLoading(false);
    }
  };

  return { putData, data, error, loading };
};

export default usePutData;
