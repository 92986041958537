import { useEffect, useState } from "react";
import axios from "axios";

export const useFetchData = (API,dependencies = []) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true);
      
            const config = {
              headers: {},
              withCredentials: true,
            };

            const auth = JSON.parse(localStorage.getItem('auth'));

            if (auth) {
              config.headers.Authorization = `Bearer ${auth.token}`;
            }
      
            const response = await axios.get(process.env.REACT_APP_API_URL + API, config);
            setData(response.data);
            setError(null);
          } catch (error) {
            console.error('Error fetching data:', error);
            setError(error?.response?.data?.error || error.message);
          } finally {
            setLoading(false);
          }
        };
          fetchData();
      }, [API, ...dependencies]);

    return { data, loading, error };
};
