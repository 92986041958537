
import { useContext } from "react"
import { SettingsContext } from "../context/settingsContext"

export const useSettingsContext = () => {
  const context = useContext(SettingsContext)

  if(!context) {
    throw Error('use settings Context must be used inside a settingscontext Provider')
  }

  return context
}