import { useHoneyContext } from '../../hooks/useHoneyContext';
import { useCartContext } from '../../hooks/useCartContext';
import { motion } from 'framer-motion';
import { useFetchData } from '../../hooks/useFetchData';

function FillJar() {
  const { cart } = useCartContext();
  const { selectedHoney } = useHoneyContext();
  const { data } = useFetchData("client-api/gifts");

  const TotalKG = cart.reduce((total, product) => {
    return total + (+product?.unitValue && !product?.categories?.includes("منتجات العسل") 
        ? product.unitValue * product.qteToBuy 
        : 0);
}, 0);


  // Check if data is valid and has giftsData
  if (!data?.success || !data?.giftsData) {
    return null;
  }

  const { level1, level2, level3 } = data.giftsData;

  // Get the highest target value for percentage calculation
  const maxTarget = Math.max(
    level3?.target || 0,
    level2?.target || 0,
    level1?.target || 0
  );

  const getPercentage = (honeyLevel) => {
    const percentage = (honeyLevel / maxTarget) * 100;
    return percentage > 100 ? 100 : percentage;
  };

  // Function to render gift indicators
  const renderGiftIndicators = (containerClass) => (
    <>
      {level1?.target && (
        <div className="indic1" style={{ bottom: getPercentage(level1.target) + '%' }}>
          <img src='/img/gift.png' alt={level1.name} />
        </div>
      )}
      {level2?.target && (
        <div className="indic2" style={{ bottom: getPercentage(level2.target) + '%' }}>
          <img src='/img/gift.png' alt={level2.name} />
        </div>
      )}
      {level3?.target && (
        <div className="indic3" style={{ bottom: getPercentage(level3.target) + '%' }}>
          <img src='/img/gift.png' alt={level3.name} />
        </div>
      )}
    </>
  );

  return (
    <motion.div 
      variants={{
        hidden: { opacity: 0 },
        show: {
          opacity: 1,
          transition: {
            staggerChildren: 0.5,
          },
        }
      }}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.7 }}
      className="fill-jar pt-12 pr-20 pr"
    >
      <img src="/img/spl.png" className="spl" alt="" />
      <img src="/img/spl2.png" className="spl2" alt="" />
      <img src="/img/flowerleft.png" className="flowerleft !w-20 !top-full" alt="" />
      <div className="df justify-center fdj !items-stretch">
        <img src="/img/fill.png" className="w-2/6 m-d-none sdnone" alt="" />
        <div className="w-1/4 m-w-60 m-items-center honey-fill-instr flex flex-col justify-center gap-2 items-center m-gap-3">
          <div className="title title3">املأ باقتك بالجملة!</div>
          <p className="ta-c">
            املأ باقتك ب{selectedHoney}! كلما زادت كمية العسل التي تختارها بالكيلوغرامات، اقتربت خطوة نحو الحصول على هدية مميزة. اختر الكمية التي ترغب فيها، وشاهد تقدمك نحو الهدية على شريط التقدم. لا تتردد في زيادة الكمية لتحصل على هديتك!
          </p>
        </div>
        <div className="fill-bar">
          <div className="prop-cont incl">
            <div className="prog" style={{ height: '100%' }}></div>
            {renderGiftIndicators("incl")}
          </div>
          <motion.div
            className="prop-cont floating--bar"
            variants={{
              hidden: { position: "absolute", opacity: 0, top: "0", left: "20%" },
              show: {
                position: "fixed",
                opacity: 1,
                top: "unset",
                left: "1rem",
                bottom: "1rem",
                transition: {
                  type: "tween",
                  duration: 1.5,
                  ease: "easeOut",
                },
              }
            }}
          >
            <div
              className="prog"
              style={{ height: `${getPercentage(TotalKG)}%` }}
            ></div>
            {renderGiftIndicators("floating--bar")}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default FillJar;