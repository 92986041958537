import { useState } from "react";
import axios from "axios";
import { NavLink } from 'react-router-dom';


function SignUpForm({ toggleForm, handleClose }) {
   const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSucess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      console.log("كلمات المرور غير متطابقة!");
      setErrorMessage('كلمات المرور غير متطابقة');
      return;
    }

    if (!formData.termsAccepted) {
      console.log("يرجى قبول الشروط والأحكام.");
      setErrorMessage('يرجى قبول الشروط والأحكام.');
      
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "client-api/signup", {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      }, {withCredentials: true});


      

      if (response.data) {
        setFormData({
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          termsAccepted: false,
        })
        setSucess(response.data.message)
        setError(null)
      }
    } catch (error) {
      console.error("خطأ أثناء التسجيل:", error);
      setError(error?.response?.data?.message || "خطأ أثناء التسجيل")
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form signup sm-w-full">
      <span className="title">التسجيل</span>
      {errorMessage && (
     <div className=" mt-2 pt-2 text-red-500 bg-red-100 border border-red-400 rounded p-2 flex items-center">
     <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 5.636l-12.728 12.728M5.636 5.636l12.728 12.728"></path>
     </svg>
     {errorMessage}
     </div>
    )}
      <form onSubmit={handleSubmit}>
        <div className="input-field !mt-4">
          <input
            type="text"
            name="name"
            placeholder="أدخل اسمك"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <i className="uil uil-user"></i>
        </div>
        <div className="input-field !mt-4">
          <input
            type="text"
            name="email"
            placeholder="أدخل بريدك الإلكتروني"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <i className="uil uil-envelope icon"></i>
        </div>
        <div className="input-field !mt-4">
          <input
            type="password"
            name="password"
            className="password"
            placeholder="أنشئ كلمة مرور"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
          <i className="uil uil-lock icon"></i>
        </div>
        <div className="input-field !mt-4">
          <input
            type="password"
            name="confirmPassword"
            className="password"
            placeholder="تأكيد كلمة المرور"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          />
          <i className="uil uil-lock icon"></i>
          <i className="uil uil-eye-slash showHidePw"></i>
        </div>
        <div className="checkbox-text">
          <div className="checkbox-content">
            <input
              type="checkbox"
              id="termCon"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={handleInputChange}
            />
            <label htmlFor="termCon" className="text !mr-2">
              أوافق على جميع 
              
               <NavLink to={"/terms"} className="text-blue-500 mr-1">الشروط والأحكام
              </NavLink> 
            </label>
          </div>
        </div>
        <div className="input-field button !mt-1">
          <button type="submit" className="simple-btn w-full !justify-center" disabled={loading}>
            {loading ? "جارٍ التسجيل..." : "التسجيل"}
          </button>
        </div>
        {
          error && <div className="error font-bold text-red-800">{error}</div>
        }
        {
          success && <div className="error font-bold text-green-600">{success}</div>
        }
        <a href="https://admin.alrheeq.com/api/auth/alrheeq/google" rel="noopener noreferrer" type="button" className="inline-block login-with-google-btn mt-2">
        تسجيل الدخول باستخدام جوجل
       </a>
      </form>
      <div className="login-signup">
        <span className="text">
          هل لديك حساب بالفعل؟
          <a href="#" className="text login-link" onClick={toggleForm}>
            سجل الدخول الآن
          </a>
        </span>
      </div>
    </div>
  );
}

export default SignUpForm;
