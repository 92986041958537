import { TbStar } from "react-icons/tb";
import { TbStarFilled } from "react-icons/tb";
import "./stars.css";

function Stars({ number }) {
  const stars = Array.from({ length: 5 }, (_, index) =>
    index < number ? <TbStarFilled key={index} /> : <TbStar key={index} />
  );
  return <div className="stars-range" key={Math.random()}>{stars}</div>;
}

export default Stars;
