import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import "./modal.css";
import Backdrop from '../Backdrop';
import { dropIn } from '../../utils/modals';
import { Cancel } from '@mui/icons-material';

function StartingPopup({handleClose, content}) {
  const [neverShow, setNeverShow] = useState(false);

  // Check if popup should be shown
  useEffect(() => {
    const hideUntil = localStorage.getItem('popupHideUntil');
    if (hideUntil && new Date(hideUntil) > new Date()) {
      handleClose();
    }
  }, [handleClose]);

  const handleCheckboxChange = (e) => {
    setNeverShow(e.target.checked);
  };

  const handleCloseWithCheck = () => {
    if (neverShow) {
      // Set expiration date to 1 week from now
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      localStorage.setItem('popupHideUntil', expirationDate.toISOString());
    }
    handleClose();
  };

  return (
    <Backdrop onClick={handleCloseWithCheck}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal pr !justify-start !overflow-x-hidden sm-df-c !w-2/3 !h-fit"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="w-full">
          <Cancel onClick={handleCloseWithCheck} className='cursor-pointer' />
        </div>
        <div className="df !gap-12 h-full ai-c sm-df-c w-full">
          <div className="img-ad w-1/2 pr z-10">
            <img 
              src={process.env.REACT_APP_UPLOADS_DIR+content?.popup.image[0].name} 
              className='w-full h-full sm-w-full object-cover' 
              alt="إعلان" 
            />
          </div>
          <div className="df-c sm-w-full w-1/2">
            <div className="title pr w-fit mb-6">
              {content?.popup.title}
              <div className="wave"></div>
            </div>
            <p>{content?.popup.description}</p>
            <a href={content?.popup.url} className="simple-btn justify-center">
              تحقق من ذلك
            </a>
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                id="neverShow"
                checked={neverShow}
                onChange={handleCheckboxChange}
                className="mr-2"
              />
              <label htmlFor="neverShow" className=" px-2 text-sm">
                عدم الظهور مرة أخرى
              </label>
            </div>
          </div>
        </div>
        <div className="blur !top-0 z-0 blurpopup !left-0"></div>
      </motion.div>
    </Backdrop>
  );
}

export default StartingPopup;