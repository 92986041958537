import { Facebook, Instagram, Telegram, Twitter, LinkedIn } from "@mui/icons-material";
import { Link } from "react-router-dom";

function TeamSlide({ mandoub }) {
  // Define social media platform configurations
  const platformConfig = {
    facebook: {
      icon: Facebook,
      color: "#4267b2"
    },
    instagram: {
      icon: Instagram,
      color: "#e1306c"
    },
    twitter: {
      icon: Twitter,
      color: "#1da1f2"
    },
    telegram: {
      icon: Telegram,
      color: "#0088cc"
    },
    linkedin: {
      icon: LinkedIn,
      color: "#0088cc"
    }
  };

  return (
    <div className="profile-card" dir="rtl">
      <img className="cov-slide" src={`${mandoub.cover? process.env.REACT_APP_UPLOADS_DIR+mandoub.cover : '/img/banner.avif'}`}  alt="" srcset="" />
      <div className="image">
        <img 
          src={`${mandoub.img? process.env.REACT_APP_UPLOADS_DIR+mandoub.img : '/img/default.png'}`} 
          alt={mandoub.fullName} 
          className="profile-img" 
        />
      </div>
      <div className="text-data">
        <Link to={`/team/${mandoub._id}`} className="name">
          {mandoub.fullName}
        </Link>
        <span className="job">مندوب {mandoub.country}, {mandoub.area}</span>
        <span className="job">الأيدي الخاص به :{mandoub.id}</span>

      </div>
      <div className="media-buttons">
        {mandoub.contact.map((contact) => {
          const platform = platformConfig[contact.platform.toLowerCase()];
          if (!platform) return null;

          const Icon = platform.icon;
          
          return (
            <a 
              key={contact.platform}
              href={contact.link} 
              style={{ background: platform.color }} 
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon className={`bx bxl-${contact.platform.toLowerCase()}`} />
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default TeamSlide;