import { IoIosSearch } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { Abc } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Product from "../product/Product";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useHoneyContext } from "../../hooks/useHoneyContext";
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined';
import { useFetchData } from "../../hooks/useFetchData";
import "./products.css";
import Empty from "../empty/Empty";
import "../sidebar/sidebar.css";
import { IoFilter } from "react-icons/io5";
import MultiRangeSlider from "../multirangeSlider/MultiRangeSlider";
import { Category } from "@mui/icons-material";
import { FaDollarSign } from "react-icons/fa6";
import { BsStarFill } from "react-icons/bs";
import { GiHoneyJar } from "react-icons/gi";
import Stars from "../stars rate/Stars";

function Products() {
  const { selectedHoney,dispatch } = useHoneyContext();
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [selectedSubCateg, setselectedSubCateg] = useState('');
  const [rateQueryString, setRateQueryString] = useState('');
  
  useEffect(() => {
    if (selectedRatings.length === 0) {
      setRateQueryString('');
    } else {
      // Create an array of rate=X parameters for each selected rating
      const rateParams = selectedRatings.map(rate => `rate=${rate}`);
      setRateQueryString(rateParams.join('&'));
    }
  }, [selectedRatings]);
  const handleRatingChange = (rating) => {
    setSelectedRatings((prev) =>
      prev.includes(rating)
        ? prev.filter((r) => r !== rating)
        : [...prev, rating]
    );
  };
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(1000000);
    const [openMenu, setOpenMenu] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [sortCriteria, setSortCriteria] = useState("");
    const { data, loading, error } = useFetchData(
      `client-api/products?page=${currentPage}&type=simple&name=${searchTerm}&category=${selectedHoney}&minprice=${minValue}&maxprice=${maxValue}&${rateQueryString}&subcategory=${selectedSubCateg}`,
      [currentPage, searchTerm, maxValue, minValue, selectedHoney, rateQueryString, selectedSubCateg]
    );  
    const {  data :childCateg} = useFetchData(`client-api/childcategories?categoryname=${selectedHoney}`, [selectedHoney]);
  const menuVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  useEffect(() => {
    setSearchTerm("")
    setselectedSubCateg("")
    
  }, [selectedHoney])
  

  const totalPages = data?.pagination.totalPages;

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 8) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 5) {
        pageNumbers.push(1, 2, 3, 4, 5, 6, '...', totalPages);
      } else if (currentPage > totalPages - 5) {
        pageNumbers.push(1, '...', totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    return pageNumbers;
  };

  const handlePageClick = (page) => {
    if (page === '...') return;
    setCurrentPage(page);
  };

  const handleSort = (criteria) => {
    setSortCriteria(criteria);
    setOpenMenu(false); // Close the menu after selecting
  };

  const sortedProducts = () => {
    if (!data?.products) return [];
    switch (sortCriteria) {
      case "name-asc":
        return [...data.products].sort((a, b) => a.name.localeCompare(b.name));
      case "name-desc":
        return [...data.products].sort((a, b) => b.name.localeCompare(a.name));
      case "price-asc":
        return [...data.products].sort((a, b) => a.price - b.price);
      case "price-desc":
        return [...data.products].sort((a, b) => b.price - a.price);
      case "date-asc":
        return [...data.products].sort((a, b) => new Date(a.date) - new Date(b.date));
      case "date-desc":
        return [...data.products].sort((a, b) => new Date(b.date) - new Date(a.date));
      default:
        return data.products;
    }
  };
  

  return (
    <div className="products px-12">
      <div className="title w-fit mx-auto mt-12 pr">
        منتجات ذات صلة بهذا العسل <div className="wave"></div>
      </div>
      <div className="df-c mt-20 p-8 prods bg-white">
        <div className="df sm-df-c m-df-c sm-g-3">
          <div className="df">
            <img src="/img/honey.png" alt="" className="w-12" />
            <div className="title pr">{selectedHoney} <div className="wave"></div></div>
          </div>
          <div className="df sm-w-full m-w-full flex-1">
            <div className="pr w-1/2 sm-w-full m-w-full mx-auto">
              <input
                type="text"
                className="input ser-in w-full"
                placeholder="ابحث..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IoIosSearch className="ser" />
            </div>
          </div>
        </div>
        <div className="df ai-stretch h-full m-df-c sb-wrap">
          <div className="sidebar m-w-full">
                <div className="df sidetitles">
                  <span>تصفية</span><IoFilter />
                </div>
                <hr />
                <div className="df sidetitles"> 
                  <span>فئات</span><Category />
                </div>
                
                {/* Move checkbox outside categ-title */}
                {
                  selectedHoney ==="" &&childCateg?.categories.map((item,index) =>(
                    <div className="group" key={index}>
                    <input type="checkbox" id="toggleSubMenu" className="toggle-checkbox" />
            
                    <label className="df categ-title" htmlFor="toggleSubMenu">
                      <div className="df" onClick={()=>{dispatch({ type: "SET-HONEY", payload: item.name })}}>
                        <GiHoneyJar /> <span>{item.name}</span>
                      </div>
                    </label>
                </div>
                  ))
                }
                {
                  selectedHoney !=="" &&childCateg?.subCategories && childCateg?.subCategories?.map((item,index) =>(
                    <div className="group" key={index}>
                    <input type="checkbox" id="toggleSubMenu" className="toggle-checkbox" />
            
                    <label className="df categ-title" htmlFor="toggleSubMenu">
                      <div className="df" onClick={()=>setselectedSubCateg(item.slug)}>
                        <GiHoneyJar /> <span>{item.name}</span>
                      </div>
                    </label>
                </div>
                  ))
                }
                <div className="df sidetitles"> 
                  <span>التقييمات</span><BsStarFill />
                </div> 
                <div className="group df-c !gap-2">
                  {[5, 4, 3, 2, 1, 0].map((num) => (
                    <div className="checkbox-wrapper-4" key={num}>
                      <input 
                       className="inp-cbx"
                       id={`rating-${num}`}
                       type="checkbox"
                       checked={selectedRatings.includes(num)}
                       onChange={() => handleRatingChange(num)}
                      />
                      <label className="cbx" htmlFor={`rating-${num}`}>
                        <span>
                          <svg width="12px" height="10px">
                            <use href="#check-4"></use>
                          </svg>
                        </span>
                        <span><Stars number={num} /></span>
                      </label>
                      <svg className="inline-svg">
                        <symbol id="check-4" viewBox="0 0 12 10">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </symbol>
                      </svg>
                    </div>
                  ))}
                </div>
                <div className="df sidetitles">
                  <span>الأسعار</span><FaDollarSign />
                </div>
                <div className="df-c">
                  <MultiRangeSlider
                    min={0}
                    max={50000}
                    onChange={({ min, max }) => { setMaxValue(max); setMinValue(min); }}
                  />
                  <div className="df font-bold jc-sb">
                    <span>{minValue}</span>
                    <span>{maxValue}</span>
                  </div>
                </div>
              </div>
          <div className="df-c w-full h-full prods">
            <div className="df p-6 bg-white jc-sb">
              <div
                className="w-3/12 m-w-60 df jc-sb prods cursor-pointer pr px-4 py-2"
                onClick={() => setOpenMenu(!openMenu)}
              >
                فرز حسب
                <AnimatePresence>
                  {openMenu && (
                    <motion.div
                      className="sort-menu df-c prods"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={menuVariants}
                      transition={{ duration: 0.2 }}
                    >
                      <div className="df op" onClick={() => handleSort("name-asc")}><Abc /> الترتيب الأبجدي (تصاعدي)</div>
                      <div className="df op" onClick={() => handleSort("name-desc")}><Abc /> الترتيب الأبجدي (تنازلي)</div>
                      <div className="df op" onClick={() => handleSort("price-asc")}><MonetizationOnOutlinedIcon /> السعر: من الأقل إلى الأعلى</div>
                      <div className="df op" onClick={() => handleSort("price-desc")}><MonetizationOnOutlinedIcon /> السعر: من الأعلى إلى الأقل</div>
                      <div className="df op" onClick={() => handleSort("date-asc")}><AlarmOnOutlinedIcon /> الأقدم أولاً</div>
                      <div className="df op" onClick={() => handleSort("date-desc")}><AlarmOnOutlinedIcon /> الأحدث أولاً</div>
                    </motion.div>
                  )}
                </AnimatePresence>
                <IoIosArrowDown />
              </div>
            </div>
            {
              loading && 
              <div className="h-screen w-full df ai-c justify-center">
                <Empty message={"جاري التحميل..."} />
              </div>
            }
            {
              error && 
              <div className="h-screen w-full df ai-c justify-center">
                <Empty message={"عذرًا، حدث خطأ ما..."} />
              </div>
            }
            {data && !loading && !error && <div className={`min-h-screen df flex-wrap !gap-6 p-5 justify-around pr bg`}>
              {sortedProducts().map((prod) => (
                <Product key={prod.id} prod={prod} />
              ))}
            </div>}
            {data && <ul className="page">
              {getPageNumbers().map((page, index) => (
                <li
                  key={index}
                  className={`page__numbers ${page === currentPage ? 'active' : ''}`}
                  onClick={() => handlePageClick(page)}
                >
                  {page}
                </li>
              ))}
            </ul>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;