import ProductImages from "../../components/product-img/ProductImages";
import Stars from "../../components/stars rate/Stars";
import "./prodcudetails.css";
import ReviewsPage from "../../components/reviews/ReviewsPage";
import { useParams } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { Helmet } from "react-helmet";
import { useCartContext } from "../../hooks/useCartContext";
import { useSettingsContext } from "../../hooks/useSettingsContext";
import LoadingPage from "../../components/loading page/LoadingPage";
import ErrorPage from "../../components/loading page/ErrorPage";
import { motion } from "framer-motion";
import { useState, useRef,useEffect } from "react";

function ProductDetails() {
  const [isAnimating, setIsAnimating] = useState(false);
  const [index, setIndex] = useState(0);
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });

  const flyOutImageRef = useRef(null);
  const flyImageVariants = {
    hidden: { opacity: 0.5, scale: 1 },
    visible: {
      opacity: 0.7,
      scale: 0.1,
      x: "100vw",
      y: "-10vh", // Move to the top
      transition: { duration: 1, ease: "easeInOut" },
    },
  };

  const { cart } = useCartContext();
  const { id } = useParams();
  const { dispatch } = useCartContext();
  const { data, loading, error } = useFetchData("client-api/product-details?id=" + id);
  const { data: giftsdata } = useFetchData("client-api/gifts");
  const { settings } = useSettingsContext();
  const [totalReviews, setTotalReviews] = useState(0);


  // Calculate reviews and ratings
  useEffect(() => {
    if (data && data?.productFound?.review) {
      setTotalReviews(data?.productFound?.review?.length);
    }
  }, [data]);
  const totalRatings = data?.productFound?.review?.reduce((acc, review) => acc + review.rate, 0) || 0;
  const averageRating = totalReviews > 0 ? totalRatings / totalReviews : 0;
  
  const TotalKG = cart.reduce((total, product) => {
    return total + (+product?.unitValue ? product.unitValue * product.qteToBuy : 0);
  }, 0);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  if (!giftsdata?.success || !giftsdata?.giftsData) {
    return <LoadingPage />;
  }

  const { level1, level2, level3 } = giftsdata?.giftsData;

  // Get the highest target value for percentage calculation
  const maxTarget = Math.max(
    level3?.target || 0,
    level2?.target || 0,
    level1?.target || 0
  );

  const getPercentage = (honeyLevel) => {
    const percentage = (honeyLevel / maxTarget) * 100;
    return percentage > 100 ? 100 : percentage;
  };

  const renderGiftIndicators = (containerClass) => (
    <>
      {level1?.target && (
        <div className="indic1" style={{ bottom: getPercentage(level1.target) + '%' }}>
          <img src='/img/gift.png' className="!object-contain !translate-x-5" alt={level1.name} />
        </div>
      )}
      {level2?.target && (
        <div className="indic2" style={{ bottom: getPercentage(level2.target) + '%' }}>
          <img src='/img/gift.png' className="!object-contain !translate-x-5" alt={level2.name} />
        </div>
      )}
      {level3?.target && (
        <div className="indic3" style={{ bottom: getPercentage(level3.target) + '%' }}>
          <img src='/img/gift.png' className="!object-contain !translate-x-5" alt={level3.name} />
        </div>
      )}
    </>
  );

  // Ensure that data and productFound exist
  const product = data?.productFound;

  const handleAddToCart = (e) => {
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ top: buttonRect.top, left: buttonRect.left });

    if (product) {
      dispatch({ type: "ADD_PRODUCT", payload: product });
    }
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 1000);
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  // Handle gallery format dynamically
  let parsedGallery = [];
  if (product.gallery && product.gallery.length > 0) {
    if (typeof product.gallery[0] === 'string') {
      try {
        parsedGallery = JSON.parse(product.gallery[0]);
      } catch (error) {
        console.error("Error parsing gallery:", error);
      }
    } else {
      parsedGallery = product.gallery;
    }
  }

  return (
    <div className="df-c mt-10 dt-p">
      {isAnimating && (
        <motion.img
          ref={flyOutImageRef}
          src={product?.image?.[0]?.url}
          alt=""
          className="fly-out-image"
          initial="hidden"
          animate="visible"
          variants={flyImageVariants}
          style={{ position: 'absolute', top: buttonPosition.top, left: buttonPosition.left }}
          onAnimationComplete={() => {
            setIsAnimating(false);
          }}
        />
      )}
      <Helmet>
        <title>{product?.seo?.title || "Default Title"}</title>
        <meta name="description" content={product?.seo?.description || "Default description"} />
        <meta property="og:image" content={product?.seo?.image[0]?.url || "default-image-url"} />
        <link rel="icon" href={product?.seo?.image[0]?.url} />
      </Helmet>

      <motion.div
        className="prop-cont floating--bar"
        animate={{
          position: "fixed",
          opacity: 1,
          top: "unset",
          left: "1rem",
          bottom: "1rem",
          transition: {
            type: "tween",
            duration: 1.5,
            ease: "easeOut",
          }
        }}
      >
        <div
          className="prog"
          style={{ height: `${getPercentage(TotalKG)}%` }}
        ></div>
        {renderGiftIndicators("floating--bar")}
      </motion.div>

      <div className="product-details sm-df-c df !gap-20 ai-fs">
        <div className="w-1/2 sm-w-full swp df-c gap-12">
          {parsedGallery.length > 0 && <ProductImages index={index} imagesUrl={parsedGallery} mainImg={product?.image?.[0]?.url} />}

          <div className="df w-fit flex-wrap mx-auto">
            {parsedGallery.length > 0 && (
              <>
                  <img src={product.image[0].url} onClick={()=>setIndex(0 )} className="w-20 h-20 object-contain rounded bg-amber-200 p-2" alt="" />
                {parsedGallery.slice().reverse().map((element, indeximg) => (
  <img 
    key={indeximg} 
    src={element?.url} 
    onClick={() => setIndex(indeximg+1)} 
    className="w-20 cursor-pointer h-20 object-contain rounded bg-amber-200 p-2" 
    alt="" 
  />
))}

              </>
            )}
          </div>
          
        </div>
        <div className="description sm-w-full w-5/12">
          <div className="df-c">
            <div className="title pr w-fit">
              {product?.name} <span class="prodPr">{product?.unitValue} {product?.unit}</span>
              <div className="wave"></div>
            </div>
            {!product?.new &&
            <div className="df">
              {product?.review.length === 0 && <div >لا توجد تقييمات بعد</div>}
              {product?.review?.length > 0 && (
                <div>
                  <div className="df">
                    <Stars number={Math.floor(averageRating)} />
                    ({totalReviews})
                  </div>
                </div>
              )}
            </div>
            }

            <div className={`title v-p ${product.discount !== product.price ? '!text-xs !text-red-700 !line-through' : ''} `}>
            {product.price} {settings?.currency.name}
            
          </div> 
             
             {product?.discount && product?.discount !== product?.price &&<div className="title v-p">
            {product?.discount} {settings?.currency.name}
            
          </div> }     
             <p className="text-sm text-gray-500">{product?.shortDescription}</p>

            <div onClick={handleAddToCart} className="simple-btn justify-center !w-full mt-12 ">
              أضف إلى السلة <div className="ic"></div>
            </div>
            <div className="title pr w-fit">
              التفاصيل
              <div className="wave"></div>
            </div>
            <div
              className="p-4"
              dangerouslySetInnerHTML={{ __html: product?.description }}
            />
          </div>
        </div>
      </div>
      {!product?.new &&
      <ReviewsPage id={id} reviews={product?.review} />
       }
    </div>
  );
}

export default ProductDetails;