import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

function VerifyPage() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [countdown, setCountdown] = useState(10);
    const { dispatch } = useAuthContext();

    useEffect(() => {
        if (!token) return;

        const verifyEmail = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}auth/verify/${token}`, {
                    method: "GET",
                    credentials: "include",
                });
        
                const result = await response.json();
        
                if (!response.ok) throw new Error(result.message || "فشل التحقق من البريد الإلكتروني");
        
                setData(result);
                dispatch({ type: "LOGIN", payload: { token: result.token, user: result.user } });
                localStorage.setItem('auth', JSON.stringify({ token: result.token, user: result.user }));
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        

        verifyEmail();
    }, [token, dispatch]);

    useEffect(() => {
        if (!data?.success) return;

        const interval = setInterval(() => {
            setCountdown((prev) => {
                if (prev === 1) {
                    clearInterval(interval);
                    navigate("/");
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval); 
    }, [data, navigate]);

    return (
        <div className="flex flex-col items-center justify-center px-4 text-center" style={{height: "60vh"}} dir="rtl">
            <h1 className="text-2xl font-bold">التحقق من البريد الإلكتروني</h1>
            <img src="/img/email.png" alt="التحقق من البريد الإلكتروني" className="" />
            
            {loading && (
                <div className="flex items-center gap-2 mt-5 text-lg font-bold text-yellow-500">
                    <span className="animate-spin w-5 h-5 border-4 border-yellow-500 border-t-transparent rounded-full"></span>
                    <p>جارٍ التحقق من بريدك الإلكتروني...</p>
                </div>
            )}

            {error && <p className="text-red-500 mt-5 text-2xl font-bold">{error.message}</p>}
            {data && <p className={`mt-5 text-2xl font-bold ${data.success ? "text-green-500" : "text-red-500"}`}>
                {data.success ? "تم التحقق من بريدك الإلكتروني بنجاح!" : "فشل التحقق من البريد الإلكتروني"}
            </p>}

            {data?.success && <p className="text-gray-500 font-bold mt-2">
                سيتم إعادة توجيهك خلال {countdown} ثانية...
            </p>}
        </div>
    );
}

export default VerifyPage;