import { useState } from "react";
import "./faq.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { GiHoneyJar } from "react-icons/gi";
import { useFetchData } from "../../hooks/useFetchData";
import { useAuthContext } from "../../hooks/useAuthContext";
import emailjs from '@emailjs/browser';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";

const Faq = () => {
  const [selectedItem, setSelectedItem] = useState("اختر العسل الذي تريده");
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [formData, setFormData] = useState({
    name_input: "",
    email_input: "",
    subject_input: "",
    message_input: "",
    quantity: "",
  });
  const { auth } = useAuthContext();

  const toggleMenu = () => {
    setIsOpenMenu((prev) => !prev);
  };

  const { data, loading, error } = useFetchData("client-api/terms");
  const { data: categ } = useFetchData("client-api/categories");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const subjectRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when form is submitted

    
    try {
      emailjs.init("_5CTA6ao5KZiE72qE");
      const emailParams = {
        name_input: formData.name_input,
        email_input: formData.email_input,
        subject_input: subjectRef.current.options[subjectRef.current.selectedIndex].text,
        selected_honey_type: selectedItem,
        quantity: formData.quantity,
        message_input: formData.message_input,
      };

      const response = await emailjs.send(
        "service_u0zyauk",
        "template_fxb61lw",
        emailParams
      );

      if (response.status === 200) {
        // Reset form
        setFormData({
          name_input: "",
          email_input: "",
          subject_input: "",
          message_input: "",
          quantity: "",
        });
        setSelectedItem("اختر العسل الذي تريده");
        setSelectedSubject("");
        
        // Configure toast with specific options
        toast.success("تم إرسال الرسالة بنجاح", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          rtl: true // Enable RTL for Arabic text
        });
      }
    } catch (error) {
      toast.error("حدث خطأ ما، حاول مرة أخرى", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        rtl: true
      });
      console.error("Error sending email:", error);
    }
    finally {
      setIsLoading(false); // Set loading to false when email is sent or an error occurs
    }
  };


  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  
  const [selectedSubject, setSelectedSubject] = useState("");

  const handleItemClick = (itemText, e) => {
    e.stopPropagation();
    setSelectedItem(itemText);
    setIsOpenMenu(false);
  };

  return (
    <div className="df-c p-4">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
  

      <div className="df justify-center ai-stretch sm-df-c sm-w-full ">
        <div className="client-info sm-w-full">
          <div className="df-c">
            <img src="/img/contact.webp" alt="" />
            
            <div className="df-c">
              <div className="title">العنوان</div>
              <p>{auth?.user.country}, {auth?.user.state}, {auth?.user.city}, {auth?.user.zipCode}</p>
            </div>

            <div className="df-c">
              <div className="title">الهاتف</div>
              <p>{auth?.user.phone}</p>
            </div>

            <div className="df-c">
              <div className="title">البريد الإلكتروني</div>
              <p>{auth?.user.email}</p>
            </div>
          </div>
        </div>

        <div id="container">
          <h1>&bull; ابق على تواصل &bull;</h1>
          <div className="icon_wrapper df justify-center">
            <img className="w-10" src="/img/mobile-phone.png" alt="" />
            <img className="w-10" src="/img/beecontact.png" alt="" />
          </div>
          
          <form onSubmit={handleSubmit} id="contact_form">
            <div className="df sm-df-c sgap-0 ">
              <div className="name flex1 sm-w-full">
                <label htmlFor="name_input"></label>
                <input
                  type="text"
                  placeholder="اسمي هو"
                  name="name_input"
                  id="name_input"
                  value={formData.name_input}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="name flex1 sm-w-full">
                <label htmlFor="email_input"></label>
                <input
                  type="email"
                  placeholder="البريد الإلكتروني"
                  name="email_input"
                  id="email_input"
                  value={formData.email_input}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="subject">
              <label htmlFor="subject_input"></label>
              <select
                name="subject_input"
                id="subject_input"
                value={selectedSubject}
                onChange={(e) => {
                  setSelectedSubject(e.target.value);
                  handleInputChange(e);
                }}
                ref={subjectRef}
                required
              >
                <option disabled hidden value="">موضوع الرسالة</option>
                <option value="start_project">أود بدء مشروع</option>
                <option value="ask_question">أود أن أطرح سؤالًا</option>
                <option value="submit_suggestion">أود أن أقدم اقتراحًا</option>
                <option value="buy_bulk">أود الشراء بالجملة</option>
                <option value="report_problem">أود الإبلاغ عن مشكلة</option>
              </select>
            </div>

            {selectedSubject === "buy_bulk" && (
              <>
                <div onClick={toggleMenu} className={`menu-item cursor-pointer pr ${isOpenMenu ? "select" : ""}`}>
                  <div className="menu-text w-full">
                    <div className="df jc-sb w-full">
                      <div className="df">
                        <GiHoneyJar /> <div>{selectedItem}</div>
                      </div>
                      <IoMdArrowDropdown />
                    </div>
                  </div>
                  
                  {isOpenMenu && categ && (
                    <div className="sub-menu !translate-x-0">
                      {categ.categories.map((category) => (
                        <div
                          key={category._id}
                          className="icon-box df"
                          onClick={(e) => handleItemClick(category.name, e)}
                        >
                          <img
                            src={category.icon[0].url}
                            style={{ height: '2rem', width: '2rem', objectFit: 'cover' }}
                            alt={category.name}
                          />
                          <div className="text">
                            <div className="title">
                              {category.name} <i className="far fa-arrow-right"></i>
                            </div>
                            <div className="sub-text">
                              {category.name} هو نوع من العسل ذو خصائص فريدة.
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="name !w-full flex1 mt-4 sm-w-full">
                  <label htmlFor="quantity"></label>
                  <input
                    type="text"
                    placeholder="الكمية"
                    name="quantity"
                    id="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </>
            )}

            <div className="message">
              <label htmlFor="message_input"></label>
              <textarea
                name="message_input"
                placeholder="أود التحدث عن"
                id="message_input"
                value={formData.message_input}
                onChange={handleInputChange}
                cols="30"
                rows="5"
                required
              ></textarea>
            </div>

            <button type="submit" className="simple-btn w-fit mx-auto">
              إرسال الرسالة
            </button>
            {isLoading && <div className="loading-message">الرجاء الانتظار...</div>}
          </form>
        </div>
      </div>
      <div className=" pr qaf-p df !justify-center">
        <img src="/img/hiddenyellow.png" class="hiddeny" />
        <div className="title w-fit pr ta-c">
  أي أسئلة؟ انظر هنا
  <svg width="100%" height="10" xmlns="http://www.w3.org/2000/svg">
    <path
      className="path"
      d="M10,5 Q150,0 290,5"
      fill="none"
      stroke="#e3be63"
      strokeWidth="5"
      strokeLinecap="round"
    />
  </svg>
</div>


      </div>

     
      {
          data && <div
          className="p-4 wrapper !mt-20"
          dangerouslySetInnerHTML={{ __html: data?.webpageData[0]?.faqPage.content }}
      />
        }
    </div>
  );
};

export default Faq;