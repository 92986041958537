import './profileHeader.css';
import useModal from '../../hooks/useModal';
import ModalContainer from '../Modal/ModalContainer';
import ReportModal from '../Modal/ReportModal';
import { DeliveryDiningOutlined, LinkedIn, Stars, Warning } from '@mui/icons-material';
import { TbMilitaryRank } from 'react-icons/tb';
import { useFetchData } from '../../hooks/useFetchData';
import { useTransactionData } from '../../hooks/useTransactionData';
import { Facebook, Instagram, Telegram, Twitter } from "@mui/icons-material";

const platformConfig = {
  facebook: {
    icon: Facebook,
    color: "#4267b2"
  },
  instagram: {
    icon: Instagram,
    color: "#e1306c"
  },
  twitter: {
    icon: Twitter,
    color: "#1da1f2"
  },
  telegram: {
    icon: Telegram,
    color: "#0088cc"
  },
  linkedin: {
    icon: LinkedIn,  
    color: "#0077b5"
  }
};

const ProfileHeader = ({id}) => {
  const {modalOpen, open, close} = useModal();
  const {data} = useFetchData("mandoub/staff?id="+id)
  const {trans} = useTransactionData("mandoub/transaction?id="+id)
  return (
    <div className="profile-header pr">
      <ModalContainer >
        {
          modalOpen && <ReportModal id={id} modalOpen={modalOpen}
          handleClose={close} />
        }
      </ModalContainer>
      <main class="cd__main">
         <div class="profile-page">
  <div class="content">
  <img className="cov-slide !object-cover" src={`${data?.mandoubs.mandoub.cover? process.env.REACT_APP_UPLOADS_DIR+data?.mandoubs.mandoub.cover : '/img/banner.avif'}`}  alt="" srcset="" />
    <div class="content__cover">
      <div class="content__avatar">
        <img src={`${data?.mandoubs.mandoub.img? process.env.REACT_APP_UPLOADS_DIR+data?.mandoubs.mandoub.img : '/img/default.png'}`} alt="" />
      </div>
      <div class="content__bull"><span></span><span></span><span></span><span></span><span></span>
      </div>
    </div>
    <div class="content__title !mt-60 df-c sm-items-center">
      <h1 className='mt-6'> {data?.mandoubs.mandoub.fullName}</h1><span> سفير مديرية {data?.mandoubs.mandoub.area}</span>
      <span> الأيدي الخاص به {data?.mandoubs.mandoub.id}</span>

      <div className="df justify-center text-red-600 cursor-pointer" onClick={open}><Warning /><span>الإبلاغ عن مشكلة</span></div>
    </div>
    <div class="content__description">
      <div className="media-buttons df w-fit mx-auto">
        {data?.mandoubs?.mandoub.contact?.map((contact) => {
          const platform = platformConfig[contact.platform.toLowerCase()];
          if (!platform) return null;

          const Icon = platform.icon;
          
          return (
            <a 
              key={contact.platform}
              href={contact.link} 
              style={{ background: platform.color }} 
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon className={`bx bxl-${contact.platform.toLowerCase()}`} />
            </a>
          );
        })}
      </div>
    </div>
    <ul class="content__list df sm-df-c !gap-4">
      <li className='df-c !items-center'><span className='df'>{trans?.transactions.length}  <DeliveryDiningOutlined /></span>إجمالي الطلبات المُسلّمة</li>
      <li className='df-c !items-center'><span className='df'>{data?.mandoubs?.mandoub.reviews.length} <Stars /></span>إجمالي التقييمات</li>
      <li className='df-c !items-center'><span className='df'>{data?.mandoubs.mandoub.weeklyContribution} <TbMilitaryRank /></span>التسلّيم الأسبوعي</li>
    </ul>
    <div class="content__button"><a class="button" href="#rate">
        <div class="button__border"></div>
        <div class="button__bg"></div>
        <p class="simple-btn">عند التعامل مع السفير يمكن اضافة تقييم</p></a></div>
  </div>
  <div class="bg">
    <div><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
    </div>
  </div>
</div>
      </main>

    </div>
  );
};

export default ProfileHeader;
