import { createContext, useEffect, useReducer } from "react";

export const CartContext = createContext();

export const CartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_PRODUCT": {
            // Handle simple products
            if (action.payload.type !== "variable") {
                const productExists = state.cart.some(
                    (product) => product._id === action.payload._id
                );
                
                if (!productExists) {
                    return {
                        ...state,
                        cart: [
                            ...state.cart,
                            {
                                ...action.payload,
                                qteToBuy: 1
                            },
                        ],
                    };
                }
                return state;
            } 
            // Handle variable products
            else {
                // Check if the exact combination exists
                const productExists = state.cart.some(
                    (product) =>
                        product._id === action.payload._id &&
                        product.selectedColor.label === action.payload.selectedColor.label &&
                        product.selectedAttribute === action.payload.selectedAttribute
                );

                if (!productExists) {
                    // Find the matching variant to get its price and other details
                    const selectedVariant = action.payload.variants.find(
                        variant => 
                            variant.color === action.payload.selectedColor.label &&
                            variant.attr === action.payload.selectedAttribute
                    );

                    if (selectedVariant) {
                        const variantPrice = selectedVariant.price 
                            ? Number(selectedVariant.price) + action.payload.price 
                            : action.payload.price;

                        return {
                            ...state,
                            cart: [
                                ...state.cart,
                                {
                                    ...action.payload,
                                    variantPrice,
                                    variantSku: selectedVariant.sku,
                                    maxQuantity: selectedVariant.qty === "-1" ? 1000 : Number(selectedVariant.qty),
                                    qteToBuy: (action.payload.qteToBuy && !isNaN(action.payload.qteToBuy) ? action.payload.qteToBuy : 1)
                                },
                            ],
                        };
                    }
                }
                return state;
            }
        }

        case "INCREASE_QUANTITY":
    return {
        ...state,
        cart: state.cart.map(product => {
            if (product._id === action.payload._id) {
                if (action.payload.type === "variable" &&
                    product.selectedColor.label === action.payload.selectedColor.label &&
                    product.selectedAttribute === action.payload.selectedAttribute) {
                    return {
                        ...product,
                        qteToBuy: Math.min(
                            product.qteToBuy + 1,
                            (product.quantity != -1 ? product.maxQuantity || (product.quantity || 1000) : 10000000)
                        )
                    };
                } else if (action.payload.type !== "variable") {
                    return {
                        ...product,
                        qteToBuy: Math.min(
                            product.qteToBuy + 1,
                            (product.quantity != -1 ? product.maxQuantity || (product.quantity || 1000) : 10000000)
                        )
                    };
                }
            }
            return product;
        }),
    };

case "DECREASE_QUANTITY":
    return {
        ...state,
        cart: state.cart.map(product => {
            if (product._id === action.payload._id) {
                if (action.payload.type === "variable" &&
                    product.selectedColor.label === action.payload.selectedColor.label &&
                    product.selectedAttribute === action.payload.selectedAttribute) {
                    return {
                        ...product,
                        qteToBuy: Math.max(product.qteToBuy - 1, 1)
                    };
                } else if (action.payload.type !== "variable") {
                    return {
                        ...product,
                        qteToBuy: Math.max(product.qteToBuy - 1, 1)
                    };
                }
            }
            return product;
        }),
    };




    case "REMOVE_PRODUCT":
        return {
            ...state,
            cart: state.cart.filter(product => {
                if (action.payload.type === "variable") {
                    return !(product._id === action.payload._id &&
                             product.selectedColor.label === action.payload.selectedColor.label &&
                             product.selectedAttribute === action.payload.selectedAttribute);
                } else {
                    return product._id !== action.payload._id;
                }
            }),
        };
    

        // Keep other cases unchanged
        case "SET_CART":
            return {
                ...state,
                cart: [action.payload],
            };

        case "EMPTY_CART":
            return {
                ...state,
                cart: [],
            };

        case "ADD_GIFT":
            const existingGift = state.cart.some(product => product.isGift);
            return {
                ...state,
                cart: existingGift
                    ? state.cart.map(product =>
                        product.isGift
                            ? { ...product, ...action.payload } 
                            : product
                    )
                    : [...state.cart, { ...action.payload, isGift: true }] 
            };

        default:
            return state;
    }
};

export const CartContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(CartReducer, { 
        cart: JSON.parse(localStorage.getItem("cart")) || [] 
    }); 

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(state.cart));
    }, [state.cart]);

    return (
        <CartContext.Provider value={{ ...state, dispatch }}>
            {children}
        </CartContext.Provider>
    );
};