import Backdrop from '../Backdrop';
import { motion } from 'framer-motion';
import { dropIn } from '../../utils/modals';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import usePutData from '../../hooks/usePutData';

function EditModal({ handleClose, element }) {
  const [selectedAdress, setSelectedAdress] = useState(element.addressType);
  const [formData, setFormData] = useState({
    name: element.name,
    house: element.house,
    city: element.city,
    state: element.state,
    zipCode: element.zipCode,
    country: element.country,
    addressType: element.addressType, // Default value
    addressTitle: element.addressTitle,
  });

  const { dispatch, auth } = useAuthContext();
  const { putData, loading, data, error } = usePutData();  // Use the new PUT hook

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      addressType: selectedAdress,
    }));
  }, [selectedAdress]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uri = `client-api/adress?id=${element._id}`;  
    await putData(uri, formData);  // Call the PUT function

    if (error) {
      console.error('Error updating address:', error);
      return;
    }

    if (!loading && !error) {
      setFormData({
        name: '',
        house: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        addressType: "عنوان الشحن",
        addressTitle: '',
      });
    }
  };

  useEffect(() => {
    if (data) {
      const { updatedUser } = data;
      dispatch({ type: 'LOGIN', payload: { ...auth,user : updatedUser } });
      handleClose();
    }
  }, [data]);

  const yemenGovernorates = [
    "أمانة العاصمة", "عدن", "حضرموت", "الحديدة", "تعز", 
    "إب", "ذمار", "صنعاء", "عمران", "المحويت", 
    "حجة", "صعدة", "البيضاء", "الجوف", "مأرب", 
    "شبوة", "أبين", "لحج", "المهرة", "الضالع", 
    "ريمة", "سقطرى"
  ];

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className="modal g0 adressmodal"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        dir="rtl"
      >
        <div className="title w-fit mx-auto pr">edit عنوان  <div className="wave"></div></div>
        <div className="df-c !gap-10 mt-12">
          <div>
            <div className="w-full">
              <div className="df sm-df-c wrap w-full justify-around">
                <label
                  htmlFor="adress"
                  className={`df-c payment-label ${selectedAdress === "adress" ? "selected" : ""}`}
                >
                  <div className="payment-card !w-16">
                    <img src="https://cdn-icons-png.flaticon.com/128/1611/1611154.png" alt="عنوان الشحن" />
                  </div>
                  <span className="ta-c font-bold text-sm">عنوان الشحن</span>
                  <input
                    type="radio"
                    name="addressType"
                    id="adress"
                    value="عنوان الشحن"
                    onChange={() => setSelectedAdress("عنوان الشحن")}
                    checked={selectedAdress === "عنوان الشحن"}
                  />
                </label>

                <label
                  htmlFor="billing"
                  className={`df-c payment-label ${selectedAdress === "billing" ? "selected" : ""}`}
                >
                  <div className="payment-card !w-16">
                    <img src="https://cdn-icons-png.flaticon.com/128/2776/2776064.png" alt="عنوان الفواتير" />
                  </div>
                  <span className="ta-c font-bold text-sm">عنوان الفواتير</span>
                  <input
                    type="radio"
                    name="addressType"
                    id="billing"
                    value="عنوان الفواتير"
                    onChange={() => setSelectedAdress("عنوان الفواتير")}
                    checked={selectedAdress === "عنوان الفواتير"}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <form className='df-c !gap-8 w-full !mt-3' onSubmit={handleSubmit}>
          <div className="df !gap-4 sm-df-c">
            <div className="df-c sm-w-full !gap-2 w-45">
              <div className="df-c pr">
                <label className="label-trans" htmlFor="name">الاسم</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="df-c !gap-2 w-full">
              <div className="df-c pr">
                <label className="label-trans" htmlFor="addressTitle">عنوان العنوان</label>
                <input
                  type="text"
                  name="addressTitle"
                  id="addressTitle"
                  value={formData.addressTitle}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="df-c !gap-2 w-full">
            <div className="df-c w-full sm-w-full !gap-2">
              <div className="df-c pr">
                <label className="label-trans" htmlFor="zipCode">الرمز البريدي</label>
                <input
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="df-c pr">
              <label className="label-trans" htmlFor="house">العنوان</label>
              <input
                type="text"
                name="house"
                id="house"
                value={formData.house}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="df-c !gap-2 w-full">
            <div className="df-c pr">
              <label className="label-trans" htmlFor="city">المدينة</label>
              <input
                type="text"
                name="city"
                id="city"
                value={formData.city}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="df-c !gap-2 w-full">
            <div className="df-c pr">
              <label className="label-trans" htmlFor="state">المحافظة</label>
              <select
                name="state"
                id="state"
                className="h-12 text-center outline-none"
                value={formData.state}
                onChange={handleInputChange}
                required
              >
                <option value="">اختر المحافظة</option>
                {yemenGovernorates.map((governorate, index) => (
                  <option key={index} value={governorate}>{governorate}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="df-c !gap-2 w-full">
            <div className="df-c pr">
              <label className="label-trans" htmlFor="country">البلد</label>
              <input
                type="text"
                name="country"
                id="country"
                value={formData.country}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="df header-btns mr-auto">
            <motion.div
              className="simple-btn-outlined"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setFormData({
                name: '',
                house: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                addressType: "عنوان الشحن",
                addressTitle: '',
              })}
            >
              إعادة تعيين
            </motion.div>
            <motion.button
              className="simple-btn"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              type="submit"
            >
              تأكيد
            </motion.button>
          </div>
        </form>
      </motion.div>
    </Backdrop>
  );
}

export default EditModal;
