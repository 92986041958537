import "./styles.css";
import { IoIosArrowForward, IoIosSearch } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { BsGrid } from "react-icons/bs";
import { Abc, Category, List } from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined';
import { useHoneyContext } from "../../hooks/useHoneyContext";
import { useState, useCallback, useEffect } from "react";
import Product from "./Product";
import { useFetchData } from "../../hooks/useFetchData";
import { IoFilter } from "react-icons/io5";
import { GiHoneyJar, GiPackedPlanks } from "react-icons/gi";
import MultiRangeSlider from "../../components/multirangeSlider/MultiRangeSlider";
import { FaDollarSign } from "react-icons/fa6";

function HoneyProductsPages() {
  const { selectedHoney } = useHoneyContext();
  const [openMenu, setOpenMenu] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100000000);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubCateg, setSelectedSubCateg] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortCriteria, setSortCriteria] = useState("");
  const [selectedChildCateg, setSelectedChildCateg] = useState("");
  const [viewMode, setViewMode] = useState("list");
  const [isFiltering, setIsFiltering] = useState(false);

  const { data: categories } = useFetchData(`client-api/categories-prod`);
  const { data, loading, error } = useFetchData(
    `client-api/honey-products?page=${currentPage}&type=variable&name=${searchTerm}&category=منتجات العسل&minprice=${minValue}&maxprice=${maxValue}&subcategory=${selectedSubCateg}&childCategories=${selectedChildCateg}`,
    [currentPage, searchTerm, maxValue, minValue, selectedHoney, selectedSubCateg, isFiltering]
  );

  useEffect(() => {
    console.log(`client-api/products?page=${currentPage}&type=variable&name=${searchTerm}&category=${selectedHoney}&minprice=${minValue}&maxprice=${maxValue}&subcategory=${selectedSubCateg}&childCategories=${selectedChildCateg}`);
    
  
    
  }, [currentPage, searchTerm, maxValue, selectedChildCateg, minValue, selectedHoney, selectedSubCateg, isFiltering])
  

  const menuVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 }
  };

  const totalPages = data?.pagination?.totalPages || 1;

  const getPageNumbers = useCallback(() => {
    const pageNumbers = [];
    if (totalPages <= 8) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 5) {
        pageNumbers.push(1, 2, 3, 4, 5, 6, '...', totalPages);
      } else if (currentPage > totalPages - 5) {
        pageNumbers.push(1, '...', totalPages - 5, totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    return pageNumbers;
  }, [currentPage, totalPages]);

  const handlePageClick = (page) => {
    if (page === '...') return;
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleSort = (criteria) => {
    setSortCriteria(criteria);
    setOpenMenu(false);
  };

  const handleSubCategSelect = (name) => {
    setSelectedSubCateg(name === selectedSubCateg ? '' : name);
    setSelectedChildCateg('');
    setCurrentPage(1);
  };

  const handleChildCategSelect = (name) => {
    setSelectedChildCateg(name === selectedChildCateg ? '' : name);
    setCurrentPage(1);
  };



  const sortedProducts = () => {
    if (!data?.products) return [];
    const products = [...data.products];
    
    switch (sortCriteria) {
      case "name-asc":
        return products.sort((a, b) => a.name.localeCompare(b.name));
      case "name-desc":
        return products.sort((a, b) => b.name.localeCompare(a.name));
      case "price-asc":
        return products.sort((a, b) => a.price - b.price);
      case "price-desc":
        return products.sort((a, b) => b.price - a.price);
      case "date-asc":
        return products.sort((a, b) => new Date(a.date) - new Date(b.date));
      case "date-desc":
        return products.sort((a, b) => new Date(b.date) - new Date(a.date));
      default:
        return products;
    }
  };

  return (
    <div className="products px-12">
      <div className="title w-fit mx-auto mt-12 pr">
        منتجات العسل
        <div className="wave"></div>
      </div>
      <div className="df-c mt-20 p-8 prods bg-white">
        <div className="df sm-df-c m-df-c sm-g-3">
          <div className="df sm-w-full m-w-full flex-1">
            <div className="pr w-1/2 sm-w-full m-w-full mx-auto">
              <input
                type="text"
                className="input ser-in w-full"
                placeholder="ابحث..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1);
                }}
              />
              <IoIosSearch className="ser" />
            </div>
          </div>
        </div>
        <div className="df ai-stretch h-full m-df-c sb-wrap">
          <div className="sidebar m-w-full">
            <div className="df sidetitles">
              <span>تصفية</span><IoFilter />
            </div>
            <hr />
            <div className="df sidetitles">
              <span>فئات</span><Category />
            </div>

            {categories?.categories[0]?.subCategories?.map((item, index) => (
              <div className="group" key={index}>
                <input type="checkbox" id={`toggleSubMenu${index}`} className="toggle-checkbox" />
                <label 
                  className={`df categ-title ${selectedSubCateg === item.name ? '!text-blue-600' : ''}`} 
                  htmlFor={`toggleSubMenu${index}`}
                >
                  <div className="df" onClick={() => handleSubCategSelect(item.name)}>
                    <GiHoneyJar className={selectedSubCateg === item.name ? '!text-blue-600' : ''} />
                    <span>{item.name}</span>
                  </div>
                  <IoIosArrowForward className="dropdown mr-auto" />
                </label>
                <div className="sub-categ-menu">
                  {item.child.map((childItem, childIndex) => (
                    <div 
                      key={childIndex}
                      className={`df sub-categ cursor-pointer ${selectedChildCateg === childItem.name ? 'text-blue-600' : ''}`}
                      onClick={() =>{
                      setSelectedSubCateg(item.name)
                      handleChildCategSelect(childItem.name)}}
                    >
                      <GiPackedPlanks className={selectedChildCateg === childItem.name ? 'text-blue-600' : ''} />
                      <span>{childItem.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className="df sidetitles">
              <span>الأسعار</span><FaDollarSign />
            </div>
            <div className="df-c">
              <MultiRangeSlider
                min={0}
                max={50000}
                onChange={({ min, max }) => {
                  setMaxValue(max);
                  setMinValue(min);
                }}
              />
              <div className="df font-bold jc-sb">
                <span>{minValue}</span>
                <span>{maxValue}</span>
              </div>
            </div>
           
          </div>

          <div className="df-c w-full h-full prods">
            <div className="df p-6 bg-white jc-sb">
              <div
                className="w-3/12 m-w-60 df jc-sb prods cursor-pointer pr px-4 py-2"
                onClick={() => setOpenMenu(!openMenu)}
              >
                فرز حسب
                <AnimatePresence>
                  {openMenu && (
                    <motion.div
                      className="sort-menu df-c prods"
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={menuVariants}
                      transition={{ duration: 0.2 }}
                    >
                      <div className="df op" onClick={() => handleSort("name-asc")}><Abc /> الترتيب الأبجدي (تصاعدي)</div>
                      <div className="df op" onClick={() => handleSort("name-desc")}><Abc /> الترتيب الأبجدي (تنازلي)</div>
                      <div className="df op" onClick={() => handleSort("price-asc")}><MonetizationOnOutlinedIcon /> السعر: من الأقل إلى الأعلى</div>
                      <div className="df op" onClick={() => handleSort("price-desc")}><MonetizationOnOutlinedIcon /> السعر: من الأعلى إلى الأقل</div>
                      <div className="df op" onClick={() => handleSort("date-asc")}><AlarmOnOutlinedIcon /> الأقدم أولاً</div>
                      <div className="df op" onClick={() => handleSort("date-desc")}><AlarmOnOutlinedIcon /> الأحدث أولاً</div>
                    </motion.div>
                  )}
                </AnimatePresence>
                <IoIosArrowDown />
              </div>

              <div className="df sdnone">
                <input
                  type="radio"
                  id="grid-view"
                  name="view"
                  value="grid"
                  checked={viewMode === "grid"}
                  onChange={() => setViewMode("grid")}
                  style={{ display: "none" }}
                />
                <label htmlFor="grid-view" className={`view cursor-pointer ${viewMode === "grid" ? "highlight" : ""}`}>
                  <BsGrid size={24} />
                </label>

                <input
                  type="radio"
                  id="list-view"
                  name="view"
                  value="list"
                  checked={viewMode === "list"}
                  onChange={() => setViewMode("list")}
                  style={{ display: "none" }}
                />
                <label htmlFor="list-view" className={`view cursor-pointer ${viewMode === "list" ? "highlight" : ""}`}>
                  <List style={{ fontSize: 28 }} />
                </label>
              </div>
            </div>

            <div className={`df flex-wrap !justify-start min-h-screen !gap-6 p-5 !items-start pr`}>
              {loading ? (
                <div className="w-full text-center">جاري التحميل...</div>
              ) : error ? (
                <div className="w-full text-center text-red-500">حدث خطأ: {error}</div>
              ) : data?.products?.length === 0 ? (
                <div className="w-full text-center">لا توجد منتجات</div>
              ) : (
                <div className={`w-full h-full df flex-wrap !gap-6 p-5 pr bg`}>
                  {sortedProducts().map((prod) => (
                    <Product key={prod._id} product={prod} list={viewMode === "list"} />
                  ))}
                </div>
              )}
            </div>

            {data?.products?.length > 0 && (
              <ul className="page">
                {getPageNumbers().map((page, index) => (
                  <li
                    key={index}
                    className={`page__numbers ${page === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageClick(page)}
                  >
                    {page}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HoneyProductsPages;