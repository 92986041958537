import { useState } from 'react';
import axios from 'axios'; // Import axios

const usePostData = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const postData = async (uri, requestData) => {
    setLoading(true);
    setError(null);
    console.log(requestData);

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+uri, requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization : `Bearer ${JSON.parse(localStorage.getItem('auth')).token}`
        },
        withCredentials:true,
      });
      

      setData(response.data); 
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);  // Set the error message
      console.log(err);
      
    } finally {
      setLoading(false);  // Set loading to false once the request completes
    }
  };

  return { postData, loading, data, error };
};

export default usePostData;
