import { useEffect, useState } from "react";
import axios from "axios";

export const useTransactionData = (API,dependencies = []) => {
    const [trans, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            setLoading(true);
      
            const config = {
              headers: {},
              withCredentials: true,
            };
      
            const response = await axios.get(process.env.REACT_APP_API_URL + API, config);
            setData(response.data);
            setError(null);
          } catch (error) {
            console.error('Error fetching data:', error);
            setError(error?.response?.data?.error || error.message);
          } finally {
            setLoading(false);
          }
        };
          fetchData();
      }, [API, ...dependencies]);

    return { trans, loading, error };
};
