import { useState, useEffect, useRef } from "react";
import ReviewsPage from "../../components/reviews/ReviewsPage";
import Stars from "../../components/stars rate/Stars";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useParams } from "react-router-dom";
import Empty from "../../components/empty/Empty";
import { useFetchData } from "../../hooks/useFetchData";
import { useCartContext } from "../../hooks/useCartContext";
import ProductImages from "../../components/product-img/ProductImages";
import "../product details/prodcudetails.css";
import { useSettingsContext } from "../../hooks/useSettingsContext";


function HoneyProductDetailsPage() {
  const [quantity, setQuantity] = useState(1);
  const [isAnimating, setIsAnimating] = useState(false);
  const flyOutImageRef = useRef(null);
  const flyImageVariants = {
    hidden: { opacity: 0.5, scale: 1 },
    visible: {
      opacity: 0.7,
      scale: 0.1,
      x: "100vw",
      transition: { duration: 1, ease: "easeInOut" },
    },
  };
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [availableAttributes, setAvailableAttributes] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [index, setIndex] = useState(0);
  const {settings} = useSettingsContext();


  const { dispatch } = useCartContext();
  const { id } = useParams();
  
  const { data, loading, error } = useFetchData("client-api/product-details?id=" + id);

  // Calculate available attributes based on selected color
  useEffect(() => {
    if (data?.productFound && data.productFound.type === "variable" && selectedColor) {
      // Filter variants by selected color and qty > 0 or qty === -1 (unlimited)
      const validVariants = data.productFound.variants.filter(
        v => v.color === selectedColor?.label && (v.qty === "-1" || parseInt(v.qty) > 0)
      );
      
      // Get unique attributes from valid variants
      const validAttributes = [...new Set(validVariants.map(v => v.attr))];
      
      // Map to full attribute objects from product's attributes array
      const availableAttrs = data.productFound.attributes.filter(
        attr => validAttributes.includes(attr.value)
      );

      console.log("Valid Variants:", validVariants);
      console.log("Valid Attributes:", validAttributes);
      console.log("Selected Color:", selectedColor);

      
      setAvailableAttributes(availableAttrs);
      
      // Reset selected attribute if it's no longer available
      if (selectedAttribute && !validAttributes.includes(selectedAttribute)) {
        setSelectedAttribute(null);
      }
    }
  }, [selectedColor, data?.productFound]);

  // Update selected variant when both color and attribute are selected
  useEffect(() => {
    if (data?.productFound && selectedColor && selectedAttribute) {
      const variant = data.productFound.variants.find(
        v => v.color === selectedColor?.label && v.attr === selectedAttribute
      );
      setSelectedVariant(variant);
    } else {
      setSelectedVariant(null);
    }
  }, [selectedColor, selectedAttribute, data?.productFound]);

  const handleColorSelect = (colorValue) => {
    setSelectedColor(colorValue);
    setSelectedAttribute(null); 
  };

  const handleAttributeSelect = (attrValue) => {
    setSelectedAttribute(attrValue);
  };

  const handleQuantityChange = (type) => {
    const maxQty = selectedVariant && selectedVariant.qty !== "-1" 
      ? parseInt(selectedVariant.qty) 
      : 1000;
      
    setQuantity((prev) => {
      if (type === "increase") {
        return prev < maxQty ? prev + 1 : prev;
      }
      return Math.max(1, prev - 1);
    });
  };

  const calculatePrice = () => {
    if (!data?.productFound) return 0;
    
    const basePrice = data.productFound.price;
    if (selectedVariant && selectedVariant.price) {
      return basePrice + Number(selectedVariant.price);
    }
    return basePrice;
  };

  const handleAddToCart = () => {
    
    if (data?.productFound && data.productFound.type === "variable") {
      if (!selectedColor) {
        console.log("Please select both color and attribute");
        
        return;
      }

      const productToAdd = {
        ...data.productFound,
        selectedColor,
        selectedAttribute,
        discount: calculatePrice(),
        variantSku: selectedVariant?.sku,
        qteToBuy: quantity
      };

      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false); 
      }, 1000); 

      console.log("this is the product to add : ", productToAdd);
      



      dispatch({ type: "ADD_PRODUCT", payload: productToAdd });
    } else if (data?.productFound) {
      console.log("we are in the second if");
      
      dispatch({ type: "ADD_PRODUCT", payload: { ...data.productFound, qteToBuy: quantity } });
    }
  };

  useEffect(() => {
    if(data?.productFound.colors.length > 0) {
      setSelectedColor(data?.productFound?.colors?.[0])
    }
  
    
  }, [data?.productFound])

  let parsedGallery = [];
  if (data?.productFound.gallery && data?.productFound.gallery.length > 0) {
    if (typeof data?.productFound.gallery[0] === 'string') {
      try {
        parsedGallery = JSON.parse(data?.productFound.gallery[0]);
      } catch (error) {
        console.error("Error parsing gallery:", error);
      }
    } else {
      parsedGallery = data?.productFound.gallery;
    }
  }

  
  
  

  const totalReviews = data?.productFound?.review?.length || 0;
  const totalRatings = data?.productFound?.review?.reduce((acc, review) => acc + review.rate, 0) || 0;
  const averageRating = totalReviews ? totalRatings / totalReviews : 0;
  console.log(data?.productFound);
  

  return (
    <main className="main">
      <div className="mainWrapper ">
        {data && <div className="mainBackground ">
          <div className="flex flex-wrap">
            <div className="flex1 sm-w-full">
              <div className="productCard_block">
                <div className="flex flex-wrap sm-w-full p-5">
                  {/* Left Side: Product Image */}
                  <div className="w-full lg:w-1/2">
                  <div className="swapp sm-w-full mx-auto swp df-c gap-12">
          {parsedGallery.length > 0 && <ProductImages index={index} imagesUrl={parsedGallery} mainImg={data?.productFound?.image?.[0]?.url} />}

          <div className="df w-fit flex-wrap justify-center">
            {parsedGallery.length > 0 && (
              <>
                  <img src={data?.productFound.image[0].url} onClick={()=>setIndex(0 )} className="w-20 h-20 object-contain rounded bg-amber-200 p-2" alt="" />
                {parsedGallery.slice().reverse().map((element, indeximg) => (
  <img 
    key={indeximg} 
    src={element?.url} 
    onClick={() => setIndex(indeximg+1)} 
    className="w-20 cursor-pointer h-20 object-contain rounded bg-amber-200 p-2" 
    alt="" 
  />
))}

              </>
            )}
          </div>
          
        </div>
                  </div>

                  {/* Right Side: Product Details */}
                  <div className="w-full lg:w-1/2">
                    <div className="productCard_rightSide">
                     

                      <div className="block_product">
                        <h2 className="block_name block_name__mainName">
                          {data.productFound.name}
                          
                        </h2>
                        <div className="block_price">
                               <p className="block_price__currency">{settings?.currency.name} {calculatePrice()}</p>
                          </div>
                          <div className="df">
                            <Stars number={averageRating} />
                            <span className="block_rating__avarage">{averageRating}</span>
                            <span className="block_rating__reviews">({totalReviews} تقييم)</span>
                          </div>
                        <h2 className="text-base font-bold">
                          {data.productFound.childCategories[0]}
                          
                        </h2>
                        
                        <p className="block_product__advantagesProduct">{data.productFound.shortDescription}</p>
                        <div className="block_descriptionInformation">
                          <div
                            className="p-4"
                            dangerouslySetInnerHTML={{ __html: data.productFound.description }}
                          />
                        </div>

                        <div className="block_informationAboutDevice">
                          
                          <div className="flex flex-wrap">
                            {/* Price and Quantity */}
                            <div className="lg:w-1/2 w-full">
                              
                              <div className="block_quantity">
                                <span className="text_specification">الكمية</span>
                                <div className="block_quantity__chooseBlock">
                                  <input
                                    className="block_quantity__number"
                                    name="quantityNumber"
                                    type="text"
                                    readOnly
                                    value={quantity}
                                  />
                                  <div className="df-c">
                                    <button
                                      className="block_quantity__button block_quantity__up"
                                      onClick={() => handleQuantityChange("increase")}
                                    >
                                      <IoIosArrowUp />
                                    </button>
                                    <button
                                      className="block_quantity__button block_quantity__down"
                                      onClick={() => handleQuantityChange("decrease")}
                                    >
                                      <IoIosArrowDown />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Color and Attribute Selection */}
                            <div className="lg:w-1/2 w-full">
                              {data.productFound.type === "variable" && (
                                <>
                                  <div className="block_goodColor">
                                    <span className="text_specification">اختر الألوان:</span>
                                    <div className="sizes df w-full">
                                      {data.productFound.colors.map((color, index) => (
                                        <div
                                          key={index}
                                          style={{ background: color.value }}
                                          className={`color ${
                                            color.value === selectedColor?.value ? "selected-col" : ""
                                          }`}
                                          onClick={() => handleColorSelect(color)}
                                        ></div>
                                      ))}
                                    </div>
                                  </div>

                                  {selectedColor && (
                                    <div className="block_goodColor">
                                      <div className="sizes df">
                                        {availableAttributes.map((attr, index) => (
                                          <div
                                            key={index}
                                            className={`size ${attr.value === selectedAttribute ? "selected-s" : ""}`}
                                            onClick={() => handleAttributeSelect(attr.value)}
                                          >
                                            {attr.label}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              
                              <button 
                                className="styling__resp simple-btn content-center mt-8" 
                                onClick={handleAddToCart}
                                //disabled={data.productFound.type === "variable" && (!selectedColor || !selectedAttribute)}
                              >
                                أضف إلى السلة
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
        {loading && <Empty message="loading ..." />}
        {error && !loading && !data && <Empty message="error loading product" />}
      </div>
      <ReviewsPage id={id} reviews={data?.productFound?.review} />
    </main>
  );
}

export default HoneyProductDetailsPage;