import { createContext, useReducer } from "react";

export const SettingsContext = createContext();

export const SettingsReducer = (state, action) => {
    switch (action.type) {
        case "SET-SETTINGS":
            return { ...state, settings: action.payload };
        default:
            return state;
    }
};

export const SettingsContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(SettingsReducer, { settings: {} });
    console.log("Setting context : ",state.settings);

    return (
        <SettingsContext.Provider value={{ ...state, dispatch }}>
            {children}
        </SettingsContext.Provider>
    );
};
