import './apply.css';
import { useState } from 'react';
import axios from 'axios'; // To send data to the backend
import SuccessNotification from '../../components/notifications/SuccessNotification';

function Apply() {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        gender: 'ذكر',
        area: 'صنعاء',
        phone: '',
        dob: '',
        address: '',
        message: '',
        upload: null,
      });
    
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(null);
      const [success, setSuccess] = useState(false);
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      };
      const handleshowSuccessNotification = () => {
        setSuccess(true);
        setTimeout(() => setSuccess(false), 10000);
      };
    
      const handleFileChange = (event) => {
        setFormData({ ...formData, upload: event.target.files[0] });
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);
    
        const data = new FormData();
        for (const key in formData) {
          data.append(key, formData[key]);
        }
    
        try {
          const response = await axios.post(process.env.REACT_APP_API_URL + 'client-api/application', data);
          setSuccess(true);
          handleshowSuccessNotification();
          const message = `تم إضافة طلب انضمام لمندوب جديد من <b>${formData.firstname} ${formData.lastname}</b>`;
          await axios.post(process.env.REACT_APP_API_URL + 'notification', {message});
  
            console.log('Data submitted successfully:', response.data);
        } catch (error) {
          setError('حدث خطأ أثناء تقديم البيانات. الرجاء المحاولة مرة أخرى.');
          console.error('Error submitting data:', error);
        } finally {
          setLoading(false);
        }
      };

  return (
    <div className="formbold-main-wrapper ">
      {success && (
        <SuccessNotification
          message="تم تقديم الطلب بنجاح! سنتواصل معك في أقرب وقت ممكن."
          img={"/img/success.png"}
          onClose={() => setSuccess(false)}
        />
      )}
      <div className="formbold-form-wrapper">
        <img src="/img/contact.png" className='mb-8' alt="Honey Delivery" />
        {loading && <p>جارٍ إرسال البيانات...</p>}
        {error && (
          <p className="text-red-500 flex items-center justify-center">
            <svg
              className="w-5 h-5 mr-2 text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.364 5.636l-12.728 12.728m0-12.728l12.728 12.728"
              ></path>
            </svg>
            {error}
          </p>
        )}
        {success && <p className="success-message text-green-500 flex items-center justify-center mx-4">تم إرسال البيانات بنجاح!</p>}
        <form onSubmit={handleSubmit}>
          <div className="formbold-input-flex ">
            <div>
              <label htmlFor="firstname" className="formbold-form-label">الاسم الأول</label>
              <input
                type="text"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                placeholder="الاسم الأول"
                className="formbold-form-input"
              />
            </div>
            <div>
              <label htmlFor="lastname" className="formbold-form-label">الاسم الأخير</label>
              <input
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                placeholder="الاسم الأخير"
                className="formbold-form-input"
              />
            </div>
          </div>
          <div className="formbold-input-flex">
            <div>
              <label htmlFor="email" className="formbold-form-label">البريد الإلكتروني</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="example@email.com"
                className="formbold-form-input"
              />
            </div>
            <div>
              <label className="formbold-form-label">الجنس</label>
              <select
                className="formbold-form-input"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value="male">ذكر</option>
                <option value="female">أنثى</option>
                <option value="others">أخرى</option>
              </select>
            </div>
          </div>
          <div className="formbold-mb-3">
            <label htmlFor="area" className="formbold-form-label">المنطقة</label>
            <select
              className="formbold-form-input"
              name="area"
              value={formData.area}
              onChange={handleChange}
            >
              <option value="صنعاء">صنعاء</option>
              <option value="عدن">عدن</option>
              <option value="تعز">تعز</option>
              <option value="الحديدة">الحديدة</option>
              <option value="إب">إب</option>
              <option value="ذمار">ذمار</option>
              <option value="مأرب">مأرب</option>
              <option value="حضرموت">حضرموت</option>
              <option value="شبوة">شبوة</option>
              <option value="البيضاء">البيضاء</option>
              <option value="الجوف">الجوف</option>
              <option value="المحويت">المحويت</option>
              <option value="ريمة">ريمة</option>
              <option value="المهرة">المهرة</option>
              <option value="سقطرى">سقطرى</option>
              <option value="عمران">عمران</option>
              <option value="حجة">حجة</option>
              <option value="صعدة">صعدة</option>
              <option value="الضالع">الضالع</option>
              <option value="لحج">لحج</option>
              <option value="أبين">أبين</option>
            </select>
          </div>
          <div className="formbold-mb-3">
            <label htmlFor="phone" className="formbold-form-label">رقم الهاتف</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="رقم الهاتف"
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label htmlFor="dob" className="formbold-form-label">تاريخ الولادة</label>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label htmlFor="address" className="formbold-form-label">العنوان</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              placeholder="العنوان"
              className="formbold-form-input"
            />
          </div>
          <div className="formbold-mb-3">
            <label htmlFor="message" className="formbold-form-label">رسالة توضيحية</label>
            <textarea
              rows="6"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="formbold-form-input"
            ></textarea>
          </div>
          <div className="formbold-form-file-flex">
            <label htmlFor="upload" className="formbold-form-label">تحميل السيرة الذاتية</label>
            <input
              type="file"
              name="upload"
              onChange={handleFileChange}
              className="formbold-form-file"
            />
          </div>
          <button type="submit" className="formbold-btn">تقديم الآن</button>
          {error && (
          <p className="text-red-500 flex items-center justify-center mt-6">
            <svg
              className="w-5 h-5 mr-2  text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M18.364 5.636l-12.728 12.728m0-12.728l12.728 12.728"
              ></path>
            </svg>
            {error}
          </p>
        )}
        </form>
      </div>
    </div>
  );
}

export default Apply;
