
import { useState } from 'react';
import axios from 'axios';

function ForgetPasswordForm({ toggleForm }) {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
  
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('');
      setSuccess('');

      // Validate email
      if (!validateEmail(email)) {
        setError('يرجى إدخال بريد إلكتروني صحيح');
        return;
      }

      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}auth/forgot-password`,
          { email },
          {
            headers: {
              Authorization : `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`
          },
          withCredentials: true,
          }
        );
        
        if (response.data?.success) {
          setSuccess(response.data?.message || 'تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني');
        } else {
          setError(response.data?.message || 'حدث خطأ ما. يرجى المحاولة مرة أخرى');
        }
      } catch (error) {
        console.error('Password reset error:', error);
        setError(
          error.response?.data?.message || 
          'حدث خطأ في الاتصال بالخادم. يرجى المحاولة مرة أخرى'
        );
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div className="form login !py-12">
        <span className="title">إعادة تعيين كلمة المرور</span>
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <input
              type="email"
              placeholder="أدخل بريدك الإلكتروني"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={loading}
            />
            <i className="uil uil-envelope icon"></i>
          </div>
          
          {error && <div className="error-message mt-4">{error}</div>}
          {success && <div className="success-message mt-4">{success}</div>}
          
          <div className="input-field button">
            <input 
              type="submit" 
              value={loading ? "جارِ الإرسال..." : "إرسال رابط إعادة التعيين"} 
              disabled={loading}
            />
          </div>
        </form>

        <div className="login-signup">
          <span className="text">
            تذكرت كلمة المرور؟{' '}
            <a href="#" className="text signup-link" onClick={toggleForm}>
              تسجيل الدخول
            </a>
          </span>
        </div>
      </div>
    );
}

export default ForgetPasswordForm;
