import "./styles.css";
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFetchData } from "../../hooks/useFetchData";

function Partners() {
  const { data, error } = useFetchData("/admin/brand");

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Ensure data and brand are available before trying to map over them
  const brandImages = data?.brand || [];

  return (
    <div className="df-c items-center !gap-12 w-full partners">
      <div className="title pr"><div className="wave"></div>شركاؤنا</div>
      <div className="our-part-cont df">
        <div className="title sdnone">شركاؤنا</div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          speed={1000}
          navigation={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            reverseDirection: true,
          }}
          pagination={{
            clickable: true,
          }}
          slidesPerView={5}
          dir="rtl"
          loop={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1, // 2 slides per view for screens smaller than 600px
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 5, // 5 slides per view for screens larger than 600px
              spaceBetween: 30,
            },
          }}
        >
          {brandImages.map((brand, index) => (
            <SwiperSlide key={brand._id}>
              <img
                src={brand.image[0]?.url} // Using the URL of the first image
                alt={brand.name}
                height="100"
                width="250"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Partners;
