import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

function Authen() {
  const { token } = useParams(); // Get token from URL
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {dispatch} = useAuthContext();

  useEffect(() => {
    if (!token) {
      setError("Token is missing");
      setLoading(false);
      return;
    }

    axios.get(`${process.env.REACT_APP_API_URL}/client-api/google?token=${token}`)
      .then((res) => {
        console.log(res);
        dispatch({ type: "LOGIN", payload: {user: res.data.user,token:res.data.token} });
        localStorage.setItem('auth', JSON.stringify({user: res.data.user,token:res.data.token}));
        
        setUser(res.data.user);
        setLoading(false);
        window.location.href = "/";
      })
      .catch((err) => {
        console.error("Error fetching user:", err);
        setError("Failed to authenticate");
        setLoading(false);
      });
  }, [token]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Welcom,  {user?.name}!</h2>
      <img src={user?.image} alt={user?.name} width={100} />
      <p>Email: {user?.email}</p>
    </div>
  );
}

export default Authen;