import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { CgAttachment } from "react-icons/cg";
import AddAdress from '../../components/Modal/AddAdress';
import ModalContainer from '../../components/Modal/ModalContainer';
import useModal from '../../hooks/useModal';
import { AddCircleOutlined, Delete, Edit } from '@mui/icons-material';
import { useAuthContext } from '../../hooks/useAuthContext';
import axios from 'axios';
import useDeleteData from '../../hooks/useDeleteData'; // Import the delete data hook
import EditModal from '../../components/Modal/EditModal';

function Settings() {
    const [droppedFiles, setDroppedFiles] = useState(null);
    const [data, setData] = useState("");
    const [addmodal, setaddmodal] = useState(false);
    const [selectedElement, setSelectedElement] = useState(false);
    const { auth,dispatch } = useAuthContext();

    const { deleteData, loading, data: deleteResponse, error } = useDeleteData(); // Destructure from useDeleteData

    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        if (auth?.user) {
            setFormData({
                name: auth.user.name || '',
                surname: auth.user.surname || '',
                email: auth.user.email || '',
                phone: auth.user.phone || '',
            });
        }
    }, [auth]);

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            setDroppedFiles(files[0]);
        }
    };
  

    useEffect(() => {
        console.log(deleteResponse,"from fronted");
        
      if(deleteResponse) {
            const {updatedUser} = deleteResponse;
            if(updatedUser) {
              dispatch({type:'LOGIN', payload:{...auth,user: updatedUser}})
            }
      }
    
    
    }, [deleteResponse])
    

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleFileChange = (e) => {
        setDroppedFiles(e.target.files[0]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formDataToSend = new FormData();
            formDataToSend.append("name", formData.name);
            formDataToSend.append("surname", formData.surname);
            formDataToSend.append("email", formData.email);
            formDataToSend.append("phone", formData.phone);
            if (droppedFiles) {
                formDataToSend.append("image", droppedFiles);
            }
            console.log(formDataToSend);
            

            const response = await axios.put(
                process.env.REACT_APP_API_URL + 'client-api/edit-info',
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                         Authorization : `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`
                    },
                    withCredentials: true,
                }
            );

            if (response.data.success) {
                console.log("User info updated:", response.data.updatedUser);
                setData(response.data);
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error updating user info:", error);
        }
    };

    const handleDelete = (id) => {
        deleteData(`client-api/adress?id=${id}`);
    };

    const { modalOpen, close, open } = useModal();

    return (
        <div className="df-c">
            <ModalContainer>
                {modalOpen && addmodal && (
                    <AddAdress
                        modalOpen={modalOpen}
                        handleClose={close}
                    />
                )}
                {modalOpen && !addmodal && selectedElement && (
                    <EditModal
                        modalOpen={modalOpen}
                        handleClose={close}
                        element={selectedElement}
                    />
                )}
            </ModalContainer>
            <div className="df-c bg-white p-8 !gap-16">
                <div className="df sm-fd-col sm-df-c sm-items-center comment-user-img ai-stretch">
                    <img
                        className="!w-40 sm-m-auto"
                        src={
                            droppedFiles
                                ? URL.createObjectURL(droppedFiles)
                                : auth?.user.image ? auth?.user.image : "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
                        }
                        alt="user"
                    />
                    <div className="df-c jc-sb flex-1">
                        <label
                            htmlFor="images"
                            className="drop-container"
                            id="dropcontainer"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >
                            <span className="rounded-icon"><CgAttachment /></span>
                            <div className="text-center">
                                <span>انقر لتحميل</span> أو اسحب وأفلت <br />
                                SVG، JPEG، PNG أو GIF
                            </div>
                            <div className="df file-uploader">
                                <input
                                    type="file"
                                    id="images"
                                    accept="image/*"
                                    className="dnone"
                                    onChange={handleFileChange}
                                    required
                                />
                                {droppedFiles && (
                                    <div className="">
                                        <p>{droppedFiles.name}</p>
                                    </div>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="df sm-df-c">
                    <div className="df-c sm-w-full !gap-2 w-45">
                        <div className="df-c pr">
                            <label className="label-trans" htmlFor="">الاسم</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Name"
                            />
                        </div>
                    </div>
                    <div className="df-c sm-w-full !gap-2 w-45">
                        <div className="df-c pr">
                            <label className="label-trans" htmlFor="">الكنية</label>
                            <input
                                type="text"
                                name="surname"
                                value={formData.surname}
                                onChange={handleInputChange}
                                placeholder="Surname"
                            />
                        </div>
                    </div>
                </div>
                <div className="df-c !gap-16">
                    <div className="df-c !gap-2 w-full">
                        <div className="df-c pr">
                            <label className="label-trans" htmlFor="">البريد الإلكتروني</label>
                            <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div className="df-c !gap-2 w-full">
                        <div className="df-c pr">
                            <label className="label-trans" htmlFor="">رقم الهاتف</label>
                            <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                placeholder="الهاتف"
                            />
                        </div>
                    </div>
                </div>
                {data.success && <p>تم حفظ التعديلات بنجاح</p>}
                <div className="df sm-df-c sm-w-full header-btns mr-auto">
                    
                    <motion.div
                        className="simple-btn sm-w-full sm-justify-center"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={handleSubmit}
                    >
                        تأكيد
                    </motion.div>
                </div>
            </div>
            <div className="bg-white p-8 df-c !gap-16">
                <div className="df jc-sb">
                    <div className="title pr">العناوين <div className="wave"></div></div>
                    <div className="add df" onClick={()=>{setaddmodal(true);open()}}>إضافة <AddCircleOutlined /></div>
                </div>
                <div className="df sm-df-c flex-wrap">
                    {
                        auth?.user.address.map((element) => (
                            <div className="adressbox" key={element._id}>
                                <div className="df-c">
                                    <div className="title">{element.addressType} </div>
                                    <div className="title">{element.name} </div>
                                    <p>
                                        {element.city}, {element.state}, {element.house}, {element.zipCode}, {element.country}
                                    </p>
                                    <button className="poubelle df" >
                                                                            <Delete onClick={() => handleDelete(element._id)} />
                                                                            <Edit onClick={()=>{setaddmodal(false);setSelectedElement(element);open()}} />
                                                                        </button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Settings;