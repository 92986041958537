import { useEffect, useState } from 'react';
import './index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import AdSlide from './AdSlide';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { useFetchData } from '../../hooks/useFetchData';

function AdSection() {
  const {data} = useFetchData("client-api/webpage");
  const [background, setBackground] = useState('bg-slide1'); 
  const [dataArray, setDataArray] = useState([]); 
  useEffect(() => {
    if(data?.webpages.homePage.carousel.carouselData) {
      setDataArray(data?.webpages.homePage.carousel.carouselData);
    }
  
   
  }, [data])
  

  const handleSlideChange = (swiper) => {
    const backgrounds = [
      'bg-slide1',
      'bg-slide2',
      'bg-slide3',
      'bg-slide4',
      'bg-slide5',
      'bg-slide6',
    ];
    setBackground(backgrounds[swiper.activeIndex]);
  };
  


  const primaryColors = ['#189ab4', '#009688', '#a1a1ff', '#e3be63', '#F79489', '#62c4ff'];

  return (
    <div className={`pr ad-sec pt-16 ${background}`}>
      <div className="hide-to-slide"></div>
      <div className="hide-to-slide2"></div>
      <img src="/img/hiddenbg.png" className="hiddenbg" alt="Hidden Background" />

      <Swiper
        direction="vertical"
        pagination={{
          clickable: true,
        }}
        navigation={true} // Enable navigation arrows
        onSlideChange={handleSlideChange}
        modules={[Pagination, Navigation]}
        speed={1000}
        allowTouchMove={false} // Disable swiping
        className="mySwiper"
      >
        {dataArray.map((element, index) => (
          <SwiperSlide key={index}>
            <AdSlide element={element} primaryColor={primaryColors[index]} />
          </SwiperSlide>
        ))}
      </Swiper>

      <img src="/img/hiddenrev-bg.png" className="hidad" />
    </div>
  );
}

export default AdSection;
