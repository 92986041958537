import { useState } from 'react';
import axios from 'axios';

const useDeleteData = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const deleteData = async (uri) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.delete(process.env.REACT_APP_API_URL + uri, { 
        headers: {
          'Content-Type': 'application/json',
           Authorization : `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`
        },
        withCredentials: true,
      });

      setData(response.data); 
    } catch (err) {
      setError(err.response ? err.response.data.message : err.message);  
      console.log(err);
    } finally {
      setLoading(false);  
    }
  };

  return { deleteData, loading, data, error };
};

export default useDeleteData;
