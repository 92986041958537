import "./topsell.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination,Autoplay} from "swiper/modules";

import ProductSlide from "../productSlide/ProductSlide";
import { useFetchData } from "../../hooks/useFetchData";

function TopSelling() {
  const {data, loading, error } = useFetchData("client-api/top-sell");

  return (
    <div className="top-sellings pr" style={{ direction: "rtl" }}>
      <img src="/img/spl3.png" className="spl3" />
      <div className="blur tl"></div>
      <div className="title ta-c mt-8">الأكثر مبيعًا</div>
      {data && data.products.length > 1 &&<div className="top-container">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          navigation={true}
          speed={1000}
          loop={true}
          autoplay={{
            delay:3000,
           disableOnInteraction: false,
          }}  
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            // Adjust for smaller screens
            600: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            // Re-define behavior for screens larger than 600px
            601: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          modules={[Navigation, Pagination, Autoplay]}

          centeredSlides={true}
          className="mySwiper"
        >
          {
            data.products.map(prod =>(
              <SwiperSlide>
                <ProductSlide prod={prod} />
              </SwiperSlide>

            ))
          }
        </Swiper>
      </div>}
    </div>
  );
}

export default TopSelling;
